import PropTypes from 'prop-types';

export const propTypes = {
  anchor: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.node.isRequired,
      paragraphs: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
      illustration: PropTypes.oneOfType([
        () => null,
        PropTypes.shape({
          src: PropTypes.string.isRequired,
          alt: PropTypes.string,
          presentation: PropTypes.bool,
        }),
      ]),
      paddingTop: PropTypes.string,
    }),
  ),
  autoplayInterval: PropTypes.string,
};

export const defaultProps = {
  list: [],
  autoplayInterval: '3000',
};
