import { all, fork } from 'redux-saga/effects';

import userSagas from './user/saga';
import staffsSagas from './staffs/saga';

import usersSagas from './users/saga';
import categoriesSagas from './categories/saga';
import boundariesSagas from './boundaries/saga';
import pointsSagas from './points/saga';

import routesSagas from './routes/saga';
import pagesSagas from './pages/saga';
import pageSagas from './page/saga';
import sectionSagas from './section/saga';
import sectionTypesSagas from './sectionTypes/saga';
import sectionTypesListSagas from './sectionTypesList/saga';

export default function* adminSagas(...arg) {
  yield all([
    fork(userSagas, ...arg),
    fork(staffsSagas, ...arg),

    fork(usersSagas, ...arg),
    fork(categoriesSagas, ...arg),
    fork(boundariesSagas, ...arg),
    fork(pointsSagas, ...arg),

    fork(routesSagas, ...arg),
    fork(pagesSagas, ...arg),
    fork(pageSagas, ...arg),
    fork(sectionSagas, ...arg),
    fork(sectionTypesSagas, ...arg),
    fork(sectionTypesListSagas, ...arg),
  ]);
}
