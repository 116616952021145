import { call, put } from 'redux-saga/effects';

import { notification } from 'antd';

import { UPDATE_ITEM as BASE } from 'constants/adminActions';
import { EmptyBoundaryParentKey } from 'constants/keys';
import { BOUNDARIES_FULL_REDUCER_PATH } from 'constants/reducerPaths';

import { coreAxios } from 'utilities/axios';
import requestWithDelay from 'utilities/requestWithDelay';
import errorParser from 'storage/utilities/errorParser';

import { itemChangingActions } from '../slice';

export const UPDATE_ITEM = `${BOUNDARIES_FULL_REDUCER_PATH}/${BASE}`;

const errorMapper = new Map([
  [
    'Bad Request',
    {
      type: 'common',
      title: 'Ошибка обноления границы',
      message: 'Проверьте правильность заполнения полей',
    },
  ],
  [
    'Empty body. Nothing to update',
    [
      {
        type: 'common',
        title: 'Ошибка обноления границы',
        message: 'Не передано данных для обновления',
      },
    ],
  ],
  [
    'Default not found',
    [{ type: 'common', title: 'Ошибка обновления', message: 'Граница не найдена' }],
  ],

  [
    'level must be a number conforming to the specified constraints',
    { type: 'level', message: 'Полу должно содержать числовое значение!' },
  ],
  [
    'osmId must be a number conforming to the specified constraints',
    { type: 'osmId', message: 'Полу должно содержать числовое значение!' },
  ],
  [
    'osmType must be a valid enum value',
    { type: 'osmType', message: 'Введено некорректное значение!' },
  ],

  [
    'Default parent not exist',
    [
      {
        type: 'common',
        title: 'Ошибка обновления границы',
        message: 'Проверьте правильность заполнения полей',
      },
      {
        type: 'parentId',
        message: 'Границы не существует!',
      },
    ],
  ],
  [
    'Default parent loop',
    [
      {
        type: 'common',
        title: 'Ошибка обновления границы',
        message: 'Проверьте правильность заполнения полей',
      },
      {
        type: 'parentId',
        message: 'Использование границы создает цикл в дереве границ!',
      },
    ],
  ],
]);

export function* updateItem({ id, formData }) {
  try {
    itemChangingActions.start();

    errorMapper.set(`Boundary ${id} not found`, errorMapper.get('Default not found'));

    if (formData.parentId) {
      formData.parentId = formData.parentId !== EmptyBoundaryParentKey ? formData.parentId : null;
    }
    if (formData.parentId) {
      errorMapper.set(
        `Parent boundary ${formData.parentId} not found`,
        errorMapper.get('Default parent not exist'),
      );
      errorMapper.set(
        `Can't use parent ${formData.parentId}. This creates loop in parents`,
        errorMapper.get('Default parent loop'),
      );
    }

    if (formData.name) {
      formData.name = JSON.parse(formData.name);
    }

    yield call(
      requestWithDelay,
      coreAxios({
        url: `/boundaries/${id}/`,
        method: 'PATCH',
        data: formData,
      }),
    );

    yield put(itemChangingActions.success());
    notification.success({
      key: `update-success-${id}`,
      message: 'Граница успешно изменена',
      onClick: () => notification.close(`update-success-${id}`),
    });
  } catch (error) {
    yield put(itemChangingActions.error(errorParser({ error, errorMapper })));
  }
}
