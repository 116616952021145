import PropTypes from 'prop-types';

import { modalPropTypes } from 'components/organisms/SubscribeForm/propTypes';

export const propTypes = {
  anchor: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
  paragraphs: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  backgroundImage: PropTypes.oneOfType([() => null, PropTypes.string]),
  ...modalPropTypes,
};

export const defaultProps = {
  paragraphs: '',
  backgroundImage: null,
};
