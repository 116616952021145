import React from 'react';

import SpinnerIcon from 'components/icons/SpinnerIcon';

import styles from './Loader.module.scss';

function Loader() {
  return (
    <div className={styles.wrapper}>
      <SpinnerIcon className={styles.loader} />
    </div>
  );
}

export default Loader;
