import { all, fork } from 'redux-saga/effects';

import fullBoundariesSagas from './full/saga';
import shortBoundariesSagas from './short/saga';
import singleBoundariesSagas from './single/saga';

export default function* boundariesSagas(...arg) {
  yield all([
    fork(fullBoundariesSagas, ...arg),
    fork(shortBoundariesSagas, ...arg),
    fork(singleBoundariesSagas, ...arg),
  ]);
}
