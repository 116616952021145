import { call, put } from 'redux-saga/effects';
import { stringify } from 'qs';
import { coreAxios } from 'utilities/axios';
import requestWithDelay from 'utilities/requestWithDelay';

import { GET_ALL_LIST as BASE } from 'constants/adminActions';
import { POINTS_FULL_REDUCER_PATH } from 'constants/reducerPaths';

import { allItemActions } from '../slice';

export const GET_ALL_LIST = `${POINTS_FULL_REDUCER_PATH}/${BASE}`;

export function* getAllList({ limit, offset, sortBy, sortType, filters: filtersObj }) {
  try {
    yield put(allItemActions.start());

    const params = {
      limit,
      offset,
      sortBy,
      sortType,
    };

    const filters = Object.entries(filtersObj).reduce((acc, [key, value]) => {
      if (value != null) {
        acc[key] = value;
      }

      return acc;
    }, {});
    if (Object.keys(filters).length !== 0) {
      params.filters = filters;
    }

    const {
      data: { list, total },
    } = yield call(
      requestWithDelay,
      coreAxios({
        url: '/points/',
        method: 'GET',
        params,
        paramsSerializer: stringify,
      }),
    );
    const initialIndex = (offset - 1) * limit + 1;
    const data = list.map((item, index) => ({
      ...item,
      key: item.id,
      index: initialIndex + index,
    }));
    yield put(allItemActions.success({ data, total }));
  } catch (error) {
    const errorResponse = error?.response?.data ?? error;
    yield put(
      allItemActions.error([
        {
          type: 'common',
          title: errorResponse.statusCode || 'Ошибка сервера',
          message: errorResponse.message || 'Попробуйте выполнить действие позднее',
        },
      ]),
    );
  }
}
