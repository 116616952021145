import { call, put } from 'redux-saga/effects';

import { landingsAxios } from 'utilities/axios';
import requestWithDelay from 'utilities/requestWithDelay';
import errorParser from 'storage/utilities/errorParser';

import { CREATE_ITEM as BASE } from 'constants/adminActions';
import { SECTION_TYPES_REDUCER_PATH } from 'constants/reducerPaths';

import { notification } from 'antd';

import { itemChangingActions } from '../slice';

export const CREATE_ITEM = `${SECTION_TYPES_REDUCER_PATH}/${BASE}`;

const errorMapper = new Map([
  ['title must be a string', { type: 'title', message: 'Введено некорректное значение!' }],
  ['title should not be empty', { type: 'title', message: 'Пожалуйста, заполните это поле!' }],
  ['name must be a string', { type: 'name', message: 'Введено некорректное значение!' }],
  ['name should not be empty', { type: 'name', message: 'Пожалуйста, заполните это поле!' }],
  [
    'Bad Request',
    {
      type: 'common',
      title: 'Ошибка создания блока',
      message: 'Проверьте правильность заполнения полей',
    },
  ],
  [
    'Default sectionType exist',
    [
      {
        type: 'common',
        title: 'Ошибка создания блока',
        message: 'Проверьте правильность заполнения полей',
      },
      {
        type: 'name',
        message: 'Блок с таким значением уже существует!',
      },
    ],
  ],
]);

export function* createItem({ formData }) {
  try {
    itemChangingActions.start();
    errorMapper.set(
      `SectionType "${formData.name}" already exist`,
      errorMapper.get('Default sectionType exist'),
    );

    yield call(
      requestWithDelay,
      landingsAxios({
        url: '/sections/types/',
        method: 'POST',
        data: formData,
      }),
    );

    yield put(itemChangingActions.success());
    notification.success({
      key: `create-success-${formData.name}`,
      message: 'Блок успешно создан',
      onClick: () => notification.close(`create-success-${formData.name}`),
    });
  } catch (error) {
    yield put(itemChangingActions.error(errorParser({ error, errorMapper })));
  }
}
