import { call, put } from 'redux-saga/effects';

import { notification } from 'antd';

import { CREATE_ITEM as BASE } from 'constants/adminActions';
import { EmptyBoundaryParentKey } from 'constants/keys';
import { BOUNDARIES_FULL_REDUCER_PATH } from 'constants/reducerPaths';

import errorParser from 'storage/utilities/errorParser';

import { coreAxios } from 'utilities/axios';
import requestWithDelay from 'utilities/requestWithDelay';

import { itemChangingActions } from '../slice';

export const CREATE_ITEM = `${BOUNDARIES_FULL_REDUCER_PATH}/${BASE}`;

const errorMapper = new Map([
  ['border should not be empty', { type: 'border', message: 'Пожалуйста, заполните это поле!' }],
  [
    'level must be a number conforming to the specified constraints',
    { type: 'level', message: 'Полу должно содержать числовое значение!' },
  ],
  ['level should not be empty', { type: 'level', message: 'Пожалуйста, заполните это поле!' }],
  [
    'osmId must be a number conforming to the specified constraints',
    { type: 'osmId', message: 'Полу должно содержать числовое значение!' },
  ],
  ['osmId should not be empty', { type: 'osmId', message: 'Пожалуйста, заполните это поле!' }],
  [
    'osmType must be a valid enum value',
    { type: 'osmType', message: 'Введено некорректное значение!' },
  ],
  ['osmType should not be empty', { type: 'osmType', message: 'Пожалуйста, заполните это поле!' }],
  [
    'Default parent not exist',
    [
      {
        type: 'common',
        title: 'Ошибка создания границы',
        message: 'Проверьте правильность заполнения полей',
      },
      {
        type: 'parentId',
        message: 'Данной границы не существует!',
      },
    ],
  ],
  [
    'Bad Request',
    {
      type: 'common',
      title: 'Ошибка создания границы',
      message: 'Проверьте правильность заполнения полей',
    },
  ],
]);

export function* createItem({ formData }) {
  try {
    itemChangingActions.start();

    if (formData.parentId) {
      formData.parentId = formData.parentId !== EmptyBoundaryParentKey ? formData.parentId : null;
    }
    if (formData.parentId) {
      errorMapper.set(
        `Parent category ${formData.parent} not found`,
        errorMapper.get('Default parent not exist'),
      );
    }

    formData.name = JSON.parse(formData.name);

    yield call(
      requestWithDelay,
      coreAxios({
        url: '/boundaries/',
        method: 'POST',
        data: formData,
      }),
    );

    yield put(itemChangingActions.success());
    notification.success({
      key: `create-success-${formData.osmId}`,
      message: 'Граница успешно создана',
      onClick: () => notification.close(`create-success-${formData.osmId}`),
    });
  } catch (error) {
    yield put(itemChangingActions.error(errorParser({ error, errorMapper })));
  }
}
