import loadable from '@loadable/component';

export const LoginPage = loadable(() =>
  import(/* webpackChunkName: "LoginPage-Admin" */ './LoginPage'),
);

export const StaffsPage = loadable(() =>
  import(/* webpackChunkName: "StaffsPage-Admin" */ './StaffsPage'),
);

export const UsersPage = loadable(() =>
  import(/* webpackChunkName: "UsersPage-Admin" */ './UsersPage'),
);

export const CategoriesPage = loadable(() =>
  import(/* webpackChunkName: "CategoriesPage-Admin" */ './CategoriesPage'),
);

export const BoundariesPage = loadable(() =>
  import(/* webpackChunkName: "BoundariesPage-Admin" */ './BoundariesPage'),
);

export const PointsPage = loadable(() =>
  import(/* webpackChunkName: "PointsPage-Admin" */ './PointsPage'),
);

export const RoutesPage = loadable(() =>
  import(/* webpackChunkName: "RoutesPage-Admin" */ './RoutesPage'),
);

export const PagesPage = loadable(() =>
  import(/* webpackChunkName: "PagesPage-Admin" */ './PagesPage'),
);

export const SectionsPage = loadable(() =>
  import(/* webpackChunkName: "SectionsPage-Admin" */ './SectionsPage'),
);
