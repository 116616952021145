import { call, put } from 'redux-saga/effects';

import { landingsAxios } from 'utilities/axios';
import requestWithDelay from 'utilities/requestWithDelay';
import errorParser from 'storage/utilities/errorParser';

import { UPDATE_ITEM as BASE } from 'constants/adminActions';
import { SECTION_TYPES_REDUCER_PATH } from 'constants/reducerPaths';

import { notification } from 'antd';

import { itemChangingActions } from '../slice';

export const UPDATE_ITEM = `${SECTION_TYPES_REDUCER_PATH}/${BASE}`;

const errorMapper = new Map([
  [
    'Bad Request',
    {
      type: 'common',
      title: 'Ошибка редактирования блока',
      message: 'Проверьте правильность заполнения полей',
    },
  ],
  [
    'Empty body. Nothing to update',
    [
      {
        type: 'common',
        title: 'Ошибка редактирования блока',
        message: 'Не передано данных для обновления',
      },
    ],
  ],
  [
    'Default not found',
    [{ type: 'common', title: 'Ошибка редактирования', message: 'Блок не найден' }],
  ],
  [
    'Default sectionType exist',
    [
      {
        type: 'common',
        title: 'Ошибка редактирования блока',
        message: 'Проверьте правильность заполнения полей',
      },
      {
        type: 'name',
        message: 'Блок с таким значением уже существует!',
      },
    ],
  ],
]);

export function* updateItem({ id, formData }) {
  try {
    itemChangingActions.start();
    errorMapper.set(`SectionType ${id} not found`, errorMapper.get('Default not found'));
    errorMapper.set(
      `SectionType "${formData.name}" already exist`,
      errorMapper.get('Default sectionType exist'),
    );

    yield call(
      requestWithDelay,
      landingsAxios({
        url: `/sections/types/${id}/`,
        method: 'PATCH',
        data: formData,
      }),
    );

    yield put(itemChangingActions.success());
    notification.success({
      key: `update-success-${id}`,
      message: 'Блок успешно изменен',
      onClick: () => notification.close(`update-success-${id}`),
    });
  } catch (error) {
    yield put(itemChangingActions.error(errorParser({ error, errorMapper })));
  }
}
