import React from 'react';
import PropTypes from 'prop-types';

import styles from './Static.module.scss';

function StaticLayout({ children }) {
  return <div className={styles.layout}>{children}</div>;
}

StaticLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export function WithStaticLayout(Component) {
  return function StaticLayoutHOC(props) {
    return (
      <StaticLayout>
        <Component {...props} />
      </StaticLayout>
    );
  };
}

export default StaticLayout;
