import { put } from 'redux-saga/effects';

import { CLEAR_ERROR as BASE } from 'constants/adminActions';
import { CATEGORIES_SHORT_REDUCER_PATH } from 'constants/reducerPaths';

import { clearErrorStatus } from '../slice';

export const CLEAR_ERROR = `${CATEGORIES_SHORT_REDUCER_PATH}/${BASE}`;

export function* clearError() {
  yield put(clearErrorStatus());
}
