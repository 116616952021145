import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import Loader from 'components/organisms/Loader';

import usePageLang from 'hooks/usePageLang';
import useLoadPageMarkup from 'hooks/useLoadPageMarkup';

import NotFoundRenderer from './NotFoundRenderer';
import ServerErrorRenderer from './ServerErrorRenderer';
import BlocksRenderer from '../BlocksRenderer';

function DynamicRoute({ slug, withLang }) {
  const { pathname } = useLocation();
  const lang = usePageLang(pathname);

  const actualSlug = useMemo(() => {
    if (!slug) {
      return null;
    }

    if (withLang) {
      return slug;
    }

    return `${lang}-${slug}`;
  }, [lang, slug, withLang]);

  const markup = useLoadPageMarkup({ path: pathname, slug: actualSlug });

  if (!markup || markup.statuses.loading) {
    return <Loader />;
  }

  if (markup.statuses.error) {
    if (markup.error?.statusCode === 404) {
      return <NotFoundRenderer lang={lang} />;
    }

    return <ServerErrorRenderer lang={lang} />;
  }

  return <BlocksRenderer config={markup.data ?? []} />;
}

DynamicRoute.propTypes = {
  slug: PropTypes.string,
  withLang: PropTypes.bool,
};

DynamicRoute.defaultProps = {
  slug: undefined,
  withLang: false,
};

export default DynamicRoute;
