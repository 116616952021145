import { put } from 'redux-saga/effects';

import { CLEAR_ID_TO_OPEN as BASE } from 'constants/adminActions';
import { ROUTES_REDUCER_PATH } from 'constants/reducerPaths';

import { cleanIdToOpen } from '../slice';

export const CLEAR_ID_TO_OPEN = `${ROUTES_REDUCER_PATH}/${BASE}`;

export function* clearIdToOpen() {
  yield put(cleanIdToOpen());
}
