import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FETCH_MARKUP_WITHOUT_CACHE } from 'storage/modules/markups/actions/fetchMarkupWithoutCache';
import { FETCH_MARKUP_WITH_CACHE } from 'storage/modules/markups/actions/fetchMarkupWithCache';

import usePreviousValue from './usePreviousValue';

export default function useLoadPageMarkup({ path, slug }) {
  const dispatch = useDispatch();
  const prevPath = usePreviousValue(path);

  const id = useMemo(() => slug ?? path, [slug, path]);
  const markup = useSelector((state) => state.markups?.[id] ?? null);

  useEffect(() => {
    if (!markup) {
      dispatch({ type: FETCH_MARKUP_WITHOUT_CACHE, id, path, slug });
      return;
    }

    if (prevPath !== path) {
      dispatch({ type: FETCH_MARKUP_WITH_CACHE, id, path, slug });
    }
  }, [id, path, prevPath, slug, dispatch, markup]);

  return markup;
}
