import React, { useCallback, useState, useEffect, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import warning from 'warning';
import _noop from 'lodash/noop';
import _debounce from 'lodash/debounce';
import { desktop, touchDevice } from 'is_js';

import useIsMounted from 'hooks/useIsMounted';

export const deviceInfo = {
  // isMobile: mobile(),
  // isTablet: tablet(),
  // isDesktop: desktop(),
  isTouchDevice: !desktop() && touchDevice(),
};

export const LAYOUTS = {
  MOBILE: 'mobile',
  MOBILE_WIDE: 'mobileWide',
  TABLET: 'tablet',
  DESKTOP: 'desktop',
  DESKTOP_WIDE: 'desktopWide',
};

// shared consts styles/screen @media mixins, don't forget to sync changes
export const MIN_MOBILE_SCREEN = 320;
export const MIN_MOBILE_WIDE_SCREEN = 640;
export const MIN_TABLET_SCREEN = 960;
export const MIN_DESKTOP_SCREEN = 1200;
export const MIN_DESKTOP_WIDE_SCREEN = 1440;

export const BREAKPOINTS = {
  [LAYOUTS.MOBILE]: { MIN: MIN_MOBILE_SCREEN, MAX: MIN_MOBILE_WIDE_SCREEN - 1 },
  [LAYOUTS.MOBILE_WIDE]: { MIN: MIN_MOBILE_WIDE_SCREEN, MAX: MIN_TABLET_SCREEN - 1 },
  [LAYOUTS.TABLET]: { MIN: MIN_TABLET_SCREEN, MAX: MIN_DESKTOP_SCREEN - 1 },
  [LAYOUTS.DESKTOP]: { MIN: MIN_DESKTOP_SCREEN, MAX: MIN_DESKTOP_WIDE_SCREEN - 1 },
  [LAYOUTS.DESKTOP_WIDE]: { MIN: MIN_DESKTOP_WIDE_SCREEN },
};

const RESIZE_DEBOUNCE_DELAY_MS = 100;

// same breakpoints for width and height, as they are interchangeable in landscape/portrait mode
export const determineDeviceByClientSize = (dimension) => {
  if (dimension <= BREAKPOINTS[LAYOUTS.MOBILE].MAX) {
    return LAYOUTS.MOBILE;
  }
  if (dimension <= BREAKPOINTS[LAYOUTS.MOBILE_WIDE].MAX) {
    return LAYOUTS.MOBILE_WIDE;
  }
  if (dimension <= BREAKPOINTS[LAYOUTS.TABLET].MAX) {
    return LAYOUTS.TABLET;
  }
  if (dimension <= BREAKPOINTS[LAYOUTS.DESKTOP].MAX) {
    return LAYOUTS.DESKTOP;
  }
  return LAYOUTS.DESKTOP_WIDE;
};

const { isTouchDevice } = deviceInfo;

const measureWindowViewport = (measureWindowInstance) => {
  if (typeof window === 'undefined') {
    return {};
  }

  const { documentElement } = (measureWindowInstance ?? window).document;

  // https://www.quirksmode.org/mobile/viewports2.html -- in article mentioned that
  // clientWidth/Height provide same values, that applied to calc @media queries constraints
  const { clientHeight: viewportHeight, clientWidth: viewportWidth } = documentElement;

  return {
    viewportWidth,
    viewportHeight,
  };
};

export const determineLayoutContext = (windowInstance) => {
  const { viewportWidth, viewportHeight } = measureWindowViewport(windowInstance);

  if (!viewportWidth && !viewportHeight) {
    // fallback for ssr, consider desktop as default device to avoid ambiguity
    return {
      viewportWidth: MIN_DESKTOP_SCREEN,
      viewportHeight: 0,
      isDesktopLayout: true,
      isDesktopDevice: true,
    };
  }

  const deviceByWidth = determineDeviceByClientSize(viewportWidth);
  const isMobileLayout = deviceByWidth === LAYOUTS.MOBILE;
  const isMobileWideLayout = deviceByWidth === LAYOUTS.MOBILE_WIDE;
  const isTabletLayout = deviceByWidth === LAYOUTS.TABLET;
  const isDesktopLayout = !isMobileLayout && !isMobileWideLayout && !isTabletLayout;
  const isDesktopWideLayout = deviceByWidth === LAYOUTS.DESKTOP_WIDE;

  return {
    viewportWidth,
    viewportHeight,

    isMobileLayout,
    isMobileWideLayout,
    isTabletLayout,
    isDesktopLayout,
    isDesktopWideLayout,

    isTouchDevice,
  };
};

// default context will only be used if LayoutContextProvider is missing in components tree
export const LayoutContext = React.createContext({
  ...determineLayoutContext(null),
  hasNoProvider: true,
});

export function LayoutContextProvider({ children, iframeInstance }) {
  const isMounted = useIsMounted();

  const windowInstance = useMemo(() => {
    if (typeof window === 'undefined' || !isMounted) {
      return null;
    }

    if (iframeInstance) {
      return iframeInstance.node?.contentWindow ?? null;
    }

    return window;
  }, [iframeInstance, isMounted]);

  const [layoutContext, setLayoutContext] = useState(() => determineLayoutContext(windowInstance));

  const windowSizeChangeHandler = useCallback(() => {
    setLayoutContext(determineLayoutContext(windowInstance));
  }, [windowInstance]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedWindowSizeChangeHandler = useCallback(
    _debounce(windowSizeChangeHandler, RESIZE_DEBOUNCE_DELAY_MS),
    [windowSizeChangeHandler],
  );

  useEffect(() => {
    if (!windowInstance) {
      return _noop; // bail out for ssr
    }

    windowSizeChangeHandler();
    windowInstance.addEventListener('resize', debouncedWindowSizeChangeHandler);
    // useEffect accept but not require return function
    // eslint-disable-next-line consistent-return
    return () => {
      windowInstance.removeEventListener('resize', debouncedWindowSizeChangeHandler);
    };
  }, [windowInstance, debouncedWindowSizeChangeHandler, windowSizeChangeHandler]);

  return <LayoutContext.Provider value={layoutContext}>{children}</LayoutContext.Provider>;
}

LayoutContextProvider.propTypes = {
  children: PropTypes.node,
  iframeInstance: PropTypes.oneOfType([() => null, PropTypes.node]),
};

LayoutContextProvider.defaultProps = {
  children: '',
  iframeInstance: null,
};

let wasWarnedAboutProvider = false;
const sanityCheck = (layoutState) => {
  const { hasNoProvider } = layoutState;
  if (hasNoProvider && !wasWarnedAboutProvider) {
    warning(
      false,
      'Missing LayoutContextProvider in rendered components hierarchy. ' +
        'Currently layoutState will not update on resize events. ' +
        'To fix that, please add LayoutContextProvider as parent in component tree.' +
        'For example in App.jsx or Root.jsx: ' +
        'export default () => <LayoutContextProvider><AppRoutes /></LayoutContextProvider>',
    );
    wasWarnedAboutProvider = true;
  }
  return layoutState;
};

export const withLayoutContext = (Component) =>
  function LayoutContextWrapper(props) {
    return (
      <LayoutContext.Consumer>
        {(layoutState) => <Component {...props} {...sanityCheck(layoutState)} />}
      </LayoutContext.Consumer>
    );
  };

export const useLayoutContext = () => sanityCheck(useContext(LayoutContext));
