import React from 'react';
import { render, hydrate } from 'react-dom';

import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { loadableReady } from '@loadable/component';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import smoothscroll from 'smoothscroll-polyfill';

import envConfig, { NODE_ENV_VARIANTS } from 'constants/config';

import history from 'utilities/history';
import createStore from 'storage/configureStore';

import Client from './client';

Sentry.init({
  enabled:
    envConfig.NODE_ENV !== NODE_ENV_VARIANTS.DEVELOPMENT &&
    envConfig.REACT_APP_SENTRY_ENABLED === 'true',
  environment: envConfig.NODE_ENV,
  dsn: envConfig.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],

  denyUrls: [/.*\/admin\/.*/],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.8,
});

smoothscroll.polyfill();

function configureApp() {
  /* eslint-disable no-underscore-dangle */
  const preloadedState = window.__PRELOADED_STATE__;
  delete window.__PRELOADED_STATE__;
  /* eslint-enable no-underscore-dangle */

  const store = createStore(preloadedState);

  return (
    <Provider store={store}>
      <Router history={history}>
        <Client />
      </Router>
    </Provider>
  );
}

loadableReady(() => {
  const app = configureApp();
  const root = document.getElementById('root');

  if (envConfig.NODE_ENV !== NODE_ENV_VARIANTS.PRODUCTION) {
    render(app, root);
  } else {
    hydrate(app, root);
  }
});
