import { call, put } from 'redux-saga/effects';

import { coreAxios } from 'utilities/axios';
import requestWithDelay from 'utilities/requestWithDelay';
import errorParser from 'storage/utilities/errorParser';

import { UPDATE_ITEM as BASE } from 'constants/adminActions';
import { CATEGORIES_FULL_REDUCER_PATH } from 'constants/reducerPaths';
import { EmptyCategoryParentKey } from 'constants/keys';

import { notification } from 'antd';

import { itemChangingActions } from '../slice';

export const UPDATE_ITEM = `${CATEGORIES_FULL_REDUCER_PATH}/${BASE}`;

const errorMapper = new Map([
  [
    'Bad Request',
    {
      type: 'common',
      title: 'Ошибка обноления категории',
      message: 'Проверьте правильность заполнения полей',
    },
  ],
  [
    'Empty body. Nothing to update',
    [
      {
        type: 'common',
        title: 'Ошибка обноления категории',
        message: 'Не передано данных для обновления',
      },
    ],
  ],
  [
    'Default not found',
    [{ type: 'common', title: 'Ошибка обновления', message: 'Категория не найдена' }],
  ],
  [
    'Default category exist',
    [
      {
        type: 'common',
        title: 'Ошибка обновления категории',
        message: 'Проверьте правильность заполнения полей',
      },
      {
        type: 'slug',
        message: 'Категория с таким slug уже существует!',
      },
    ],
  ],
  [
    'Default parent not exist',
    [
      {
        type: 'common',
        title: 'Ошибка обновления категории',
        message: 'Проверьте правильность заполнения полей',
      },
      {
        type: 'parentId',
        message: 'Родительской категории не существует!',
      },
    ],
  ],
  [
    'Default parent loop',
    [
      {
        type: 'common',
        title: 'Ошибка обновления категории',
        message: 'Проверьте правильность заполнения полей',
      },
      {
        type: 'parentId',
        message: 'Использование категории в роли родительской создает цикл в дереве категорий!',
      },
    ],
  ],
]);

export function* updateItem({ id, formData }) {
  try {
    itemChangingActions.start();

    errorMapper.set(`Category ${id} not found`, errorMapper.get('Default not found'));
    errorMapper.set(
      `Category with slug ${formData.slug} already exist`,
      errorMapper.get('Default category exist'),
    );

    if (formData.name) {
      formData.name = JSON.parse(formData.name);
    }
    if (formData.parentId) {
      formData.parent = formData.parentId !== EmptyCategoryParentKey ? formData.parentId : null;
    }
    if (formData.parent) {
      errorMapper.set(
        `Parent category ${formData.parent} not found`,
        errorMapper.get('Default parent not exist'),
      );
      errorMapper.set(
        `Can't use parent ${formData.parent}. This creates loop in parents`,
        errorMapper.get('Default parent loop'),
      );
    }

    yield call(
      requestWithDelay,
      coreAxios({
        url: `/categories/${id}/`,
        method: 'PATCH',
        data: formData,
      }),
    );

    yield put(itemChangingActions.success());
    notification.success({
      key: `update-success-${id}`,
      message: 'Категория успешно изменена',
      onClick: () => notification.close(`update-success-${id}`),
    });
  } catch (error) {
    yield put(itemChangingActions.error(errorParser({ error, errorMapper })));
  }
}
