import { call, put } from 'redux-saga/effects';

import { landingsAxios } from 'utilities/axios';
import requestWithDelay from 'utilities/requestWithDelay';
import errorParser from 'storage/utilities/errorParser';

import { CREATE_ITEM as BASE } from 'constants/adminActions';
import { ROUTES_REDUCER_PATH } from 'constants/reducerPaths';

import { notification } from 'antd';

import { itemChangingActions } from '../../slice';

export const CREATE_ITEM_WITH_NEW_PAGE = `${ROUTES_REDUCER_PATH}/${BASE}/new`;

const errorMapper = new Map([
  [
    'name must be shorter than or equal to 256 characters',
    { type: 'name', message: 'Длина введенного текста не должна превышать 256 символов' },
  ],
  [
    'name must be longer than or equal to 0 characters',
    { type: 'name', message: 'Пожалуйста, заполните это поле!' },
  ],
  ['name must be a string', { type: 'name', message: 'Введено некорректное значение!' }],
  ['name should not be empty', { type: 'name', message: 'Пожалуйста, заполните это поле!' }],
  ['route.path must be a string', { type: 'path', message: 'Введено некорректное значение!' }],
  ['route.path should not be empty', { type: 'path', message: 'Пожалуйста, заполните это поле!' }],
  [
    'Bad Request',
    {
      type: 'common',
      title: 'Ошибка создания страницы',
      message: 'Проверьте правильность заполнения полей',
    },
  ],
  [
    'Default route exist',
    [
      {
        type: 'common',
        title: 'Ошибка создания страницы',
        message: 'Проверьте правильность заполнения полей',
      },
      {
        type: 'path',
        message: 'Страница с таким URL уже существует!',
      },
    ],
  ],
  [
    'Default page exist',
    [
      {
        type: 'common',
        title: 'Ошибка создания страницы',
        message: 'Проверьте правильность заполнения полей',
      },
      {
        type: 'slug',
        message: 'Страница с таким slug уже существует!',
      },
    ],
  ],
]);

export function* createItemWithNewPage({ formData }) {
  try {
    itemChangingActions.start();
    errorMapper.set(
      `Route "${formData.path}" already exist`,
      errorMapper.get('Default route exist'),
    );
    errorMapper.set(
      `Page with slug "${formData.slug}" already exist`,
      errorMapper.get('Default page exist'),
    );

    const preparedData = {
      ...formData,
      route: formData,
    };

    const { data } = yield call(
      requestWithDelay,
      landingsAxios({
        url: '/pages/',
        method: 'POST',
        data: preparedData,
      }),
    );

    yield put(itemChangingActions.success({ idToOpen: data.id }));
    notification.success({
      key: `create-success-${formData.path}`,
      message: 'Страница успешно создана',
      onClick: () => notification.close(`create-success-${formData.path}`),
    });
  } catch (error) {
    yield put(itemChangingActions.error(errorParser({ error, errorMapper })));
  }
}
