import { all, fork } from 'redux-saga/effects';

import routesSagas from './routes/saga';
import markupsSagas from './markups/saga';
import adminSagas from './admin/saga';
import commonSagas from './common/saga';

export default function* rootSagas(...arg) {
  yield all([
    fork(routesSagas, ...arg),
    fork(markupsSagas, ...arg),
    fork(adminSagas, ...arg),
    fork(commonSagas, ...arg),
  ]);
}
