import { call, put } from 'redux-saga/effects';

import { coreAxios } from 'utilities/axios';
import requestWithDelay from 'utilities/requestWithDelay';
import errorParser from 'storage/utilities/errorParser';

import { IMPORT_ALL_LIST as BASE } from 'constants/adminActions';
import { CATEGORIES_FULL_REDUCER_PATH } from 'constants/reducerPaths';

import { itemChangingActions } from '../slice';

export const IMPORT_ALL_LIST = `${CATEGORIES_FULL_REDUCER_PATH}/${BASE}`;

const errorMapper = new Map([
  [
    'Bad Request',
    {
      type: 'common',
      title: 'Ошибка импорта категорий',
      message: 'Проверьте правильность csv-файла',
    },
  ],
  [
    'Csv file expected',
    [
      {
        type: 'common',
        title: 'Ошибка импорта категорий',
        message: 'Не передано данных для импорта',
      },
    ],
  ],
]);

export function* importList({ clearBefore, formData, meta }) {
  try {
    yield call(
      requestWithDelay,
      coreAxios({
        url: `/categories/import/?clearBefore=${clearBefore}`,
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    );

    yield call(meta.resolve);
    yield put(itemChangingActions.success());
  } catch (error) {
    yield put(itemChangingActions.error(errorParser({ error, errorMapper })));
    yield call(meta.reject, { skipNotification: true });
  }
}
