import { call } from 'redux-saga/effects';
import { stringify } from 'qs';

import { landingsAxios } from 'utilities/axios';

export default function getMarkup({ path, slug }) {
  const params = {};
  if (path) {
    params.path = path;
  }
  if (slug) {
    params.slug = slug;
  }
  const query = stringify(params);

  return call(landingsAxios, {
    url: `/pages/markup/?${query}`,
    method: 'GET',
  });
}
