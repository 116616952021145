import { call, put } from 'redux-saga/effects';
import { coreAxios } from 'utilities/axios';
import requestWithDelay from 'utilities/requestWithDelay';

import { loginUserActions } from '../slice';

export const LOGIN_USER_BY_MAIL = 'admin/user/LOGIN_USER_BY_MAIL';

const errorMapper = new Map([
  [
    'email must be an email',
    { type: 'email', message: 'Введенное значение не является email‘ом!' },
  ],
  ['email should not be empty', { type: 'email', message: 'Пожалуйста, заполните это поле!' }],
  [
    'password must be longer than or equal to 8 characters',
    { type: 'password', message: 'Пожалуйста, заполните это поле!' },
  ],
  ['password must be a string', { type: 'password', message: 'Введено некорректное значение!' }],
  [
    'password should not be empty',
    { type: 'password', message: 'Пожалуйста, заполните это поле!' },
  ],
  ['Invalid email or password', { type: 'common', message: 'Неверный логин и/или пароль' }],
]);

export function* loginUserByEmail({ formData }) {
  try {
    yield put(loginUserActions.start());
    const { data } = yield call(
      requestWithDelay,
      coreAxios({
        url: '/auth/login/',
        method: 'POST',
        data: formData,
      }),
    );

    yield put(loginUserActions.success(data));
  } catch (error) {
    const errorResponse = error?.response?.data ?? error;

    if (Array.isArray(errorResponse.message)) {
      const errors = errorResponse.message.map(
        (message) => errorMapper.get(message) ?? { type: 'other', message },
      );
      yield put(loginUserActions.error(errors));
      return;
    }

    const localMessage = errorMapper.get(errorResponse.message) ?? {
      type: 'common',
      message: errorResponse.message,
    };
    yield put(loginUserActions.error([localMessage]));
  }
}
