import { createSlice } from '@reduxjs/toolkit';
import * as BASE_LOADABLE from 'storage/utilities/baseLoadable';

const initialState = {
  statuses: BASE_LOADABLE.initial(),
  shouldReload: false,
  data: null,
  error: null,
};

const { reducer, actions } = createSlice({
  name: 'categories.short',
  initialState,
  reducers: {
    resetStateToInitial: () => initialState,
    clearErrorStatus: (state) => {
      state.statuses = BASE_LOADABLE.clear();
      state.error = null;
    },
    startAllRequest: (state) => {
      state.statuses = BASE_LOADABLE.begin();
      state.data = null;
      state.error = null;
      state.shouldReload = false;
    },
    successfulAllRequest: (state, action) => {
      state.statuses = BASE_LOADABLE.success();
      state.data = action.payload;
    },
    allRequestWithError: (state, action) => {
      state.statuses = BASE_LOADABLE.error();
      state.error = action.payload;
    },
  },
});

export const allItemActions = {
  start: actions.startAllRequest,
  success: actions.successfulAllRequest,
  error: actions.allRequestWithError,
};

export const { clearErrorStatus, resetStateToInitial } = actions;

export default reducer;
