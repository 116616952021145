import PropTypes from 'prop-types';

import _omit from 'lodash/omit';

import { MENU_TYPES } from 'constants/common';

export const menuItemPropTypes = {
  type: PropTypes.oneOf(Object.values(MENU_TYPES)).isRequired,
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  eventData: PropTypes.object,
  isMobile: PropTypes.bool,
};

export const menuItemDefaultProps = {
  eventData: {},
};

const menuListPropType = PropTypes.arrayOf(PropTypes.shape(_omit(menuItemPropTypes, ['isMobile'])));

export const supportLinkPropTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  eventData: PropTypes.object,
  className: PropTypes.string,
};

export const supportLinkDefaultProps = {
  eventData: {},
  className: undefined,
};

export const otherLangLinkPropTypes = {
  href: PropTypes.string.isRequired,
  longText: PropTypes.string.isRequired,
  shortText: PropTypes.string.isRequired,
  flagIcon: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  eventData: PropTypes.object,
  className: PropTypes.string,
  showLongText: PropTypes.bool,
};

export const otherLangLinkDefaultProps = {
  tooltip: '',
  eventData: {},
  className: undefined,
  showLongText: false,
};

export const donateModalPropTypes = {
  illustration: PropTypes.string,
  title: PropTypes.node.isRequired,
  paragraphs: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      text: PropTypes.string,
      logo: PropTypes.string,
    }),
  ),
  eventData: PropTypes.object,
};

export const rightSidePropTypes = {
  className: PropTypes.string,
  showSupportLink: PropTypes.bool.isRequired,
  supportLink: PropTypes.oneOfType([
    () => null,
    PropTypes.shape(_omit(supportLinkPropTypes, ['className', 'onClick'])),
  ]),
  onSupportLinkClick: PropTypes.func.isRequired,
  otherLangLink: PropTypes.oneOfType([
    () => null,
    PropTypes.shape(_omit(otherLangLinkPropTypes, ['className', 'showLongText'])),
  ]),
};

export const mobileMenuPropTypes = {
  list: menuListPropType.isRequired,
  rightSideProps: PropTypes.shape(_omit(rightSidePropTypes, ['className'])).isRequired,
  onScrollToAnchor: PropTypes.func.isRequired,
};

export const desktopMenuPropTypes = {
  firstBlockAnchor: PropTypes.string.isRequired,
  list: menuListPropType.isRequired,
  onLogoClick: PropTypes.func.isRequired,
  permanentShowLogo: PropTypes.bool.isRequired,
};

export const propTypes = {
  firstBlockAnchor: PropTypes.string,
  mobileMenu: menuListPropType,
  desktopMenu: menuListPropType,
  permanentShowLogo: PropTypes.bool,
  supportLink: rightSidePropTypes.supportLink,
  otherLangLink: rightSidePropTypes.otherLangLink,
  donateModal: PropTypes.oneOfType([() => null, PropTypes.shape(donateModalPropTypes)]),
  disableFixed: PropTypes.bool,
};

export const defaultProps = {
  firstBlockAnchor: '',
  mobileMenu: [],
  desktopMenu: [],
  permanentShowLogo: false,
  supportLink: null,
  otherLangLink: null,
  disableFixed: false,
};
