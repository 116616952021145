import React from 'react';
import cls from 'classnames';

import { ALIGN_VARIANTS, COLOR_VARIANTS, SIZE_VARIANTS } from '../constants';
import { propTypes, defaultProps } from '../propTypes';

import skeletonStyles from './Skeleton.module.scss';

function ParagraphsSkeleton({ children, className, color, size, align }) {
  return (
    <div className={className}>
      {React.Children.map(children, () => (
        <p className={skeletonStyles.item}>
          <span
            className={cls(
              skeletonStyles.line,
              skeletonStyles[`${align}Align`],
              skeletonStyles[`${color}Color`],
              skeletonStyles[`${size}Size`],
            )}
          />
          <span
            className={cls(
              skeletonStyles.line,
              skeletonStyles[`${align}Align`],
              skeletonStyles[`${color}Color`],
              skeletonStyles[`${size}Size`],
            )}
          />
          <span
            className={cls(
              skeletonStyles.line,
              skeletonStyles[`${align}Align`],
              skeletonStyles[`${color}Color`],
              skeletonStyles[`${size}Size`],
            )}
          />
          <span
            className={cls(
              skeletonStyles.line,
              skeletonStyles[`${align}Align`],
              skeletonStyles[`${color}Color`],
              skeletonStyles[`${size}Size`],
            )}
          />
        </p>
      ))}
    </div>
  );
}

ParagraphsSkeleton.propTypes = propTypes;
ParagraphsSkeleton.defaultProps = defaultProps;

export { ALIGN_VARIANTS, COLOR_VARIANTS, SIZE_VARIANTS };
export default ParagraphsSkeleton;
