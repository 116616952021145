import { call, put } from 'redux-saga/effects';
import { landingsAxios } from 'utilities/axios';

import { fetchRoutesActions } from '../slice';

export const FETCH_ROUTES_LIST = 'routes/FETCH_LIST';

export function* fetchRoutesList() {
  try {
    yield put(fetchRoutesActions.startLoading());
    const { data } = yield call(landingsAxios, {
      url: '/routes/',
      method: 'GET',
    });
    yield put(fetchRoutesActions.successLoaded(data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    yield put(fetchRoutesActions.loadedWithError(error));
  }
}
