import { takeEvery } from 'redux-saga/effects';

import { GET_ALL_LIST, getAllList } from './actions/getList';
import { CLEAR_ERROR, clearError } from './actions/clearError';
import { RESET_TO_INITIAL, resetToInitial } from './actions/resetToInitial';

export default function* categoriesSagas() {
  yield takeEvery(GET_ALL_LIST, getAllList);
  yield takeEvery(CLEAR_ERROR, clearError);
  yield takeEvery(RESET_TO_INITIAL, resetToInitial);
}
