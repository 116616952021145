import { combineReducers } from '@reduxjs/toolkit';

import full from './full';
import short from './short';
import single from './single';

export default combineReducers({
  full,
  short,
  single,
});
