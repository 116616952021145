import React from 'react';
import cls from 'classnames';

import Container from 'components/base/Container';

import TitleSkeleton, {
  COLOR_VARIANTS as TITLE_COLORS,
  TAG_VARIANTS as TITLE_TAGS,
} from 'components/atoms/Title/Skeleton';
import ParagraphsSkeleton, {
  COLOR_VARIANTS as PARAGRAPHS_COLORS,
} from 'components/atoms/Paragraphs/Skeleton';
import ButtonSkeleton, { COLOR_VARIANTS as BUTTON_COLORS } from 'components/atoms/Button/Skeleton';

import useIsMounted from 'hooks/useIsMounted';

import useParagraphsAlign from '../../hooks/useParagraphsAlign';
import useActionSize from '../../hooks/useActionSize';
import useTitleAlign from '../hooks/useTitleAlign';

import { propTypes, defaultProps } from '../propTypes';

import commonStyles from '../Common.module.scss';
import skeletonStyles from './Skeleton.module.scss';

function MainScreenSkeleton({ paragraphs, action, mobileIllustration, tabletIllustration }) {
  const isMounted = useIsMounted();

  const titleAlign = useTitleAlign(isMounted);
  const paragraphsAlign = useParagraphsAlign({ isMounted });
  const actionSize = useActionSize(isMounted);

  return (
    <div className={commonStyles.ComponentRoot}>
      <Container>
        <div className={commonStyles.wrapper}>
          <div className={cls(commonStyles.content, skeletonStyles.content)}>
            {mobileIllustration && (
              <div
                className={cls(
                  commonStyles.illustration,
                  commonStyles.mobileIllustration,
                  skeletonStyles.illustration,
                )}
              />
            )}
            {tabletIllustration && (
              <div
                className={cls(
                  commonStyles.illustration,
                  commonStyles.tabletIllustration,
                  skeletonStyles.illustration,
                )}
              />
            )}
            <TitleSkeleton
              tag={TITLE_TAGS.h1}
              color={TITLE_COLORS.ACCENT}
              align={titleAlign}
              className={commonStyles.title}
              contentClassName={skeletonStyles.titleContent}
            />
            {Boolean(paragraphs) && (
              <ParagraphsSkeleton
                className={commonStyles.description}
                align={paragraphsAlign}
                color={PARAGRAPHS_COLORS.DARK_400}
              >
                {paragraphs}
              </ParagraphsSkeleton>
            )}
            {action && (
              <div className={commonStyles.actionWrapper}>
                <ButtonSkeleton
                  className={cls(commonStyles.action, skeletonStyles.action)}
                  size={actionSize}
                  color={BUTTON_COLORS.BASE_DARK}
                />
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}

MainScreenSkeleton.propTypes = propTypes;
MainScreenSkeleton.defaultProps = defaultProps;

export default MainScreenSkeleton;
