import { call, put } from 'redux-saga/effects';
import { coreAxios } from 'utilities/axios';
import requestWithDelay from 'utilities/requestWithDelay';

import { GET_ALL_LIST as BASE } from 'constants/adminActions';
import { BOUNDARIES_FULL_REDUCER_PATH } from 'constants/reducerPaths';

import { listActions } from '../slice';

export const GET_ALL_LIST = `${BOUNDARIES_FULL_REDUCER_PATH}/${BASE}`;

export function* getAllList({ limit, offset }) {
  try {
    yield put(listActions.start());
    const {
      data: { list, total },
    } = yield call(
      requestWithDelay,
      coreAxios({
        url: `/boundaries/countries/?limit=${limit}&offset=${offset}`,
        method: 'GET',
      }),
    );
    const initialIndex = (offset - 1) * limit + 1;
    const data = list.map((item, index) => ({
      ...item,
      indent: 1,
      key: item.id,
      index: initialIndex + index,
    }));
    yield put(listActions.success({ data, total }));
  } catch (error) {
    const errorResponse = error?.response?.data ?? error;
    yield put(
      listActions.error([
        {
          type: 'common',
          title: errorResponse.statusCode || 'Ошибка сервера',
          message: errorResponse.message || 'Попробуйте выполнить действие позднее',
        },
      ]),
    );
  }
}
