import { call, put } from 'redux-saga/effects';

import { coreAxios } from 'utilities/axios';

import { logoutUserAction } from '../slice';

export const LOGOUT_USER = 'admin/user/LOGOUT_USER';

export function* logoutUser() {
  yield call(coreAxios, {
    url: '/auth/logout/',
    method: 'POST',
  });

  yield put(logoutUserAction());
}
