import loadable from '@loadable/component';

const RuServerErrorPage = loadable(() =>
  import(/* webpackChunkName: "RuServerErrorPage-Static" */ './RuServerErrorPage'),
);

const EnServerErrorPage = loadable(() =>
  import(/* webpackChunkName: "EnServerErrorPage-Static" */ './EnServerErrorPage'),
);

export const ServerErrorPages = {
  en: EnServerErrorPage,
  ru: RuServerErrorPage,
};
