import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import cls from 'classnames';
import _noop from 'lodash/noop';

import LogoIcon from 'components/icons/LogoIcon';

import { VIEW_TYPE_VARIANTS } from 'constants/common';

import useSendEvent from 'hooks/useSendEvent';

import styles from './LogoLink.module.scss';

const AVAILABLE_LANG_LIST = ['/ru/', '/en/'];

function LogoLink({ className, fullLogo, viewType, to, onClick }) {
  const { pathname } = useLocation();
  const sendEventCreator = useSendEvent();

  const href = useMemo(() => {
    if (to) {
      return to;
    }

    const pageLang = AVAILABLE_LANG_LIST.find((lang) => pathname.startsWith(lang));
    if (pageLang) {
      return pageLang;
    }

    return '/';
  }, [pathname, to]);

  const eventObj = useMemo(() => {
    if (href === pathname) {
      return { event: 'scroll-to', type: 'top' };
    }

    return { event: 'navigate', to: href };
  }, [href, pathname]);
  const sendEventHandler = sendEventCreator({ eventObj });

  const lickClickHandler = useCallback(
    (event) => {
      sendEventHandler();
      onClick(event);
    },
    [onClick, sendEventHandler],
  );

  return (
    <Link
      className={cls(styles.LogoLink, className)}
      title="Travelee"
      to={href}
      onClick={lickClickHandler}
    >
      <LogoIcon fullLogo={fullLogo} viewType={viewType} className={styles.icon} />
    </Link>
  );
}

LogoLink.propTypes = {
  viewType: PropTypes.oneOf(Object.values(VIEW_TYPE_VARIANTS)),
  className: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
  fullLogo: PropTypes.bool,
};

LogoLink.defaultProps = {
  viewType: VIEW_TYPE_VARIANTS.DARK,
  className: undefined,
  to: undefined,
  onClick: _noop,
  fullLogo: false,
};

export { VIEW_TYPE_VARIANTS };

export default LogoLink;
