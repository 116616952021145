import React from 'react';
import { useLocation } from 'react-router-dom';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import Routes from 'routes';

import envConfig from 'constants/config';

import usePageLang from 'hooks/usePageLang';
import { LayoutContextProvider } from 'utilities/layoutContext';

function Client() {
  const { pathname } = useLocation();
  const lang = usePageLang(pathname);

  return (
    <LayoutContextProvider>
      <GoogleReCaptchaProvider
        reCaptchaKey={envConfig.REACT_APP_RECAPTCHA_KEY}
        language={lang}
        scriptProps={{ async: true, appendTo: 'body' }}
      >
        <Routes />
      </GoogleReCaptchaProvider>
    </LayoutContextProvider>
  );
}

export default Client;
