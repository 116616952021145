import { createSlice } from '@reduxjs/toolkit';
import * as BASE_LOADABLE from 'storage/utilities/baseLoadable';

const initialState = {
  statuses: {
    login: BASE_LOADABLE.initial(),
    session: BASE_LOADABLE.initial(),
    refresh: BASE_LOADABLE.initial(),
  },
  data: null,
  error: null,
};

const { reducer, actions } = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginStarted: (state) => {
      state.statuses.login = BASE_LOADABLE.begin();
    },
    loginSuccess: (state, action) => {
      state.statuses.login = BASE_LOADABLE.success();
      state.statuses.session = BASE_LOADABLE.success();
      state.data = action.payload;
      state.error = null;
    },
    loginError: (state, action) => {
      state.statuses.login = BASE_LOADABLE.error();
      state.data = null;
      state.error = action.payload;
    },
    getSessionStarted: (state) => {
      state.statuses.session = BASE_LOADABLE.begin();
    },
    getSessionSuccess: (state, action) => {
      state.statuses.session = BASE_LOADABLE.success();
      state.data = action.payload;
      state.error = null;
    },
    getSessionError: (state) => {
      state.statuses.session = BASE_LOADABLE.error();
      state.data = null;
      state.error = null;
    },
    logoutUser: () => initialState,
  },
});

export const loginUserActions = {
  start: actions.loginStarted,
  success: actions.loginSuccess,
  error: actions.loginError,
};

export const getSessionActions = {
  start: actions.getSessionStarted,
  success: actions.getSessionSuccess,
  error: actions.getSessionError,
};

export const logoutUserAction = actions.logoutUser;

export default reducer;
