import React from 'react';
import cls from 'classnames';

import _noop from 'lodash/noop';

import Container from 'components/base/Container';

import LogoLink from 'components/atoms/LogoLink';
import MenuBurger from 'components/atoms/MenuBurger';
import ButtonSkeleton, { SIZES_VARIANTS, COLOR_VARIANTS } from 'components/atoms/Button/Skeleton';

import { VIEW_TYPE_VARIANTS } from 'constants/common';

import { propTypes, defaultProps } from '../propTypes';

import commonStyles from '../Common.module.scss';
import skeletonStyles from './Skeleton.module.scss';

function HeaderSkeleton({ desktopMenu, supportLink, otherLangLink }) {
  return (
    <header className={cls(commonStyles.ComponentRoot, skeletonStyles.ComponentRoot)}>
      <Container>
        <div className={commonStyles.wrapper}>
          <div className={cls(commonStyles.leftSide, skeletonStyles.leftSide)}>
            <MenuBurger
              className={skeletonStyles.menuBurger}
              isOpen={false}
              viewType={VIEW_TYPE_VARIANTS.DARK}
              onClick={_noop}
            />
            <div className={cls(skeletonStyles.desktopMenu, skeletonStyles.linksList)}>
              {desktopMenu.map(({ key }, index) => (
                <div
                  key={key || index}
                  className={cls(skeletonStyles.linkItem, skeletonStyles.menuLink)}
                />
              ))}
            </div>
          </div>
          <div className={commonStyles.middleSide}>
            <LogoLink className={commonStyles.logo} viewType={VIEW_TYPE_VARIANTS.ACCENT} fullLogo />
          </div>
          <div className={commonStyles.rightSide}>
            {supportLink && (
              <ButtonSkeleton
                className={cls(skeletonStyles.linkItem, skeletonStyles.supportLink)}
                size={SIZES_VARIANTS.SMALL}
                color={COLOR_VARIANTS.TRANSPARENT}
              />
            )}
            {otherLangLink && (
              <ButtonSkeleton
                className={cls(skeletonStyles.linkItem, skeletonStyles.otherLangLink)}
                size={SIZES_VARIANTS.SMALL}
                color={COLOR_VARIANTS.OUTLINED}
              />
            )}
          </div>
        </div>
      </Container>
    </header>
  );
}

HeaderSkeleton.propTypes = propTypes;
HeaderSkeleton.defaultProps = defaultProps;

export default HeaderSkeleton;
