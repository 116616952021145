import PropTypes from 'prop-types';

import _omit from 'lodash/omit';

import { sizeProp } from 'components/atoms/Button/propTypes';
import { modalPropTypes } from 'components/organisms/SubscribeForm/propTypes';

export const subscribeModalProps = {
  actionSize: sizeProp,
  subscribeModal: PropTypes.shape({
    title: PropTypes.node.isRequired,
    paragraphs: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  }),
  ...modalPropTypes,
};

export const propTypes = {
  anchor: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
  paragraphs: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  action: PropTypes.oneOfType([
    () => null,
    PropTypes.shape({
      href: PropTypes.string,
      text: PropTypes.node.isRequired,
      eventData: PropTypes.object,
    }),
  ]),
  backgroundImage: PropTypes.oneOfType([
    () => null,
    PropTypes.shape({
      desktop: PropTypes.string,
      mobile: PropTypes.string,
    }),
  ]),
  mobileIllustration: PropTypes.oneOfType([() => null, PropTypes.string]),
  tabletIllustration: PropTypes.oneOfType([() => null, PropTypes.string]),
  showArrowDown: PropTypes.bool,
  ..._omit(subscribeModalProps, 'actionSize'),
};

export const defaultProps = {
  paragraphs: '',
  action: null,
  backgroundImage: null,
  mobileIllustration: null,
  tabletIllustration: null,
  showArrowDown: false,
};
