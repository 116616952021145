export const COLOR_VARIANTS = {
  DARK_100: 'dark100',
  DARK_200: 'dark200',
  DARK_300: 'dark300',
  DARK_400: 'dark400',
  BASE_LIGHT: 'light',
};

export const SIZE_VARIANTS = {
  SMALL: 'small',
  MEDIUM: 'medium',
  // LARGE: 'large',
};

export const ALIGN_VARIANTS = {
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center',
  JUSTIFY: 'justify',
};
