import { put } from 'redux-saga/effects';
import { INIT_RELOAD as BASE } from 'constants/adminActions';
import { PAGE_REDUCER_PATH } from 'constants/reducerPaths';

import { initReload } from '../slice';

export const INIT_RELOAD = `${PAGE_REDUCER_PATH}/${BASE}`;

export function* shouldInitReload() {
  yield put(initReload());
}
