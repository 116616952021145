import { call, put } from 'redux-saga/effects';
import { landingsAxios } from 'utilities/axios';
import requestWithDelay from 'utilities/requestWithDelay';

import { GET_ALL_LIST as BASE } from 'constants/adminActions';
import { ROUTES_REDUCER_PATH } from 'constants/reducerPaths';

import { allItemActions } from '../slice';

export const GET_ALL_LIST = `${ROUTES_REDUCER_PATH}/${BASE}`;

function resortRoutes(route) {
  if (!route.children || !route.children.length) {
    return route;
  }

  if (route.children[0].path === '/') {
    const firstChild = route.children.shift();
    route.children.push(firstChild);
  }

  const lastChild = route.children[route.children.length - 1];
  const baseAddChild = { key: `${lastChild.key}add`, addRow: true };

  if (lastChild.id) {
    route.children.push({ basePath: lastChild.basePath, ...baseAddChild });
  } else {
    const basePath = `${lastChild.basePath.split('/').slice(0, -1).join('/')}/`;
    route.children.push({ basePath, ...baseAddChild });
  }

  route.children = route.children.map(resortRoutes);
  return route;
}

function prepareRoutes(routes) {
  const result = { children: [] };

  routes.forEach((route) => {
    const chunks = route.path.split('/');
    chunks.shift();
    const chunksCount = chunks.length - 1;

    let basePath = '';

    return chunks.reduce((acc, part, index) => {
      const chunk = `/${part}`;

      let childIndex = acc.children.findIndex((item) => item.path === chunk);
      basePath += chunk;

      if (childIndex === -1) {
        acc.children.push({ key: basePath, basePath, path: chunk, children: [] });
        childIndex = acc.children.length - 1;
      }

      if (index === chunksCount) {
        acc.children[childIndex] = {
          ...route,
          ...acc.children[childIndex],
          key: route.path,
          route: route.path,
          children: undefined,
        };
      }

      return acc.children[childIndex];
    }, result);
  });

  if (!result.children.length) {
    return [{ key: 'add', basePath: '/', addRow: true }];
  }

  return resortRoutes(result).children;
}

export function* getAllList() {
  try {
    yield put(allItemActions.start());
    const { data } = yield call(
      requestWithDelay,
      landingsAxios({
        url: '/routes/all/',
        method: 'GET',
      }),
    );
    yield put(allItemActions.success(prepareRoutes(data)));
  } catch (error) {
    const errorResponse = error?.response?.data ?? error;
    yield put(
      allItemActions.error([
        {
          type: 'common',
          title: errorResponse.statusCode || 'Ошибка сервера',
          message: errorResponse.message || 'Попробуйте выполнить действие позднее',
        },
      ]),
    );
  }
}
