import { takeEvery, fork, all } from 'redux-saga/effects';

import { RESET_TO_INITIAL, resetToInitial } from './actions/resetToInitial';
import { CLEAR_ERROR, clearError } from './actions/clearError';
import { CLEAR_ID_TO_OPEN, clearIdToOpen } from './actions/clearIdToOpen';
import { INIT_RELOAD, shouldInitReload } from './actions/initReload';
import { GET_ALL_LIST, getAllList } from './actions/getList';
import { DELETE_ITEM, deleteItem } from './actions/deleteItem';

import { createItemSagas } from './actions/createItem';
import { updateItemSagas } from './actions/updateItem';

export default function* routesSagas(...arg) {
  yield takeEvery(RESET_TO_INITIAL, resetToInitial);
  yield takeEvery(CLEAR_ERROR, clearError);
  yield takeEvery(CLEAR_ID_TO_OPEN, clearIdToOpen);
  yield takeEvery(INIT_RELOAD, shouldInitReload);
  yield takeEvery(GET_ALL_LIST, getAllList);
  yield takeEvery(DELETE_ITEM, deleteItem);

  yield all([fork(createItemSagas, ...arg), fork(updateItemSagas, ...arg)]);
}
