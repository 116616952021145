import { call, put } from 'redux-saga/effects';

import { coreAxios } from 'utilities/axios';
import requestWithDelay from 'utilities/requestWithDelay';
import errorParser from 'storage/utilities/errorParser';

import { CREATE_ITEM as BASE } from 'constants/adminActions';
import { POINTS_FULL_REDUCER_PATH } from 'constants/reducerPaths';

import { notification } from 'antd';

import { itemChangingActions } from '../slice';
import plainObjectToFormData from '../helpers/plainObjectToFormData';

export const CREATE_ITEM = `${POINTS_FULL_REDUCER_PATH}/${BASE}`;

const errorMapper = new Map([
  ['name must be an object', { type: 'name', message: 'Пожалуйста, заполните это поле!' }],
  [
    'location should not be empty',
    {
      type: [
        ['location', 'coordinates', '0'],
        ['location', 'coordinates', '1'],
      ],
      message: 'Пожалуйста, заполните это поле!',
    },
  ],
  ['address must be a string', { type: 'address', message: 'Введено некорректное значение!' }],
  ['imageUri must be a string', { type: 'imageUri', message: 'Введено некорректное значение!' }],
  [
    'imageSource must be a string',
    { type: 'imageSource', message: 'Введено некорректное значение!' },
  ],
  ['sourceUri must be a string', { type: 'sourceUri', message: 'Введено некорректное значение!' }],
  [
    'Default category not found',
    [
      {
        type: 'common',
        title: 'Ошибка создания точки',
        message: 'Проверьте правильность заполнения полей',
      },
      {
        type: 'categories',
        message: 'Выбрана несуществующая категория!',
      },
    ],
  ],
  [
    'Bad Request',
    {
      type: 'common',
      title: 'Ошибка создания точки',
      message: 'Проверьте правильность заполнения полей',
    },
  ],
]);

export function* createItem({ formData }) {
  try {
    itemChangingActions.start();

    if (formData.categories?.length) {
      formData.categories.forEach((category) => {
        errorMapper.set(
          `Category ${category} not found`,
          errorMapper.get('Default category not found'),
        );
      });
    }

    formData.name = JSON.parse(formData.name);
    formData.location = formData.location.coordinates;

    yield call(
      requestWithDelay,
      coreAxios({
        url: '/points/',
        method: 'POST',
        data: plainObjectToFormData(formData),
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    );

    yield put(itemChangingActions.success());
    notification.success({
      key: `create-success-${formData.osmId}`,
      message: 'Точка успешно создана',
      onClick: () => notification.close(`create-success-${formData.osmId}`),
    });
  } catch (error) {
    yield put(itemChangingActions.error(errorParser({ error, errorMapper })));
  }
}
