import { useCallback } from 'react';

import _noop from 'lodash/noop';
import _flow from 'lodash/fp/flow';
import sendEvent from 'utilities/sendEvent';

export default function useSendEvent() {
  return useCallback(
    ({ actionHandler = _noop, eventObj = {}, getEventObj }) =>
      _flow(
        (...arg) => {
          const objSendToEvent = getEventObj ? getEventObj(...arg) : eventObj;

          if (Object.keys(objSendToEvent).length) {
            sendEvent(objSendToEvent);
          }

          return arg;
        },
        (...args) => {
          const [arg] = args;
          actionHandler(...arg);
        },
      ),
    [],
  );
}
