import { call, put } from 'redux-saga/effects';

import { coreAxios } from 'utilities/axios';
import requestWithDelay from 'utilities/requestWithDelay';
import errorParser from 'storage/utilities/errorParser';

import { UPDATE_ITEM as BASE } from 'constants/adminActions';
import { STAFFS_REDUCER_PATH } from 'constants/reducerPaths';

import { notification } from 'antd';

import { itemChangingActions } from '../slice';

export const UPDATE_ITEM = `${STAFFS_REDUCER_PATH}/${BASE}`;

const errorMapper = new Map([
  [
    'Bad Request',
    {
      type: 'common',
      title: 'Ошибка обноления пользователя',
      message: 'Проверьте правильность заполнения полей',
    },
  ],
  [
    'Empty body. Nothing to update',
    [
      {
        type: 'common',
        title: 'Ошибка обноления пользователя',
        message: 'Не передано данных для обновления',
      },
    ],
  ],
  [
    'Default not found',
    [{ type: 'common', title: 'Ошибка обновления', message: 'Пользователь не найден' }],
  ],
  [
    'Default user exist',
    [
      {
        type: 'common',
        title: 'Ошибка обновления пользователя',
        message: 'Проверьте правильность заполнения полей',
      },
      {
        type: 'email',
        message: 'Пользователь с таким E-mail уже существует',
      },
    ],
  ],
]);

export function* updateItem({ id, formData }) {
  try {
    itemChangingActions.start();
    errorMapper.set(`User ${id} not found`, errorMapper.get('Default not found'));
    errorMapper.set(
      `User with email ${formData.email} already exist`,
      errorMapper.get('Default user exist'),
    );

    yield call(
      requestWithDelay,
      coreAxios({
        url: `/staffs/${id}/`,
        method: 'PATCH',
        data: formData,
      }),
    );

    yield put(itemChangingActions.success());
    notification.success({
      key: `update-success-${id}`,
      message: 'Пользователь успешно изменен',
      onClick: () => notification.close(`update-success-${id}`),
    });
  } catch (error) {
    yield put(itemChangingActions.error(errorParser({ error, errorMapper })));
  }
}
