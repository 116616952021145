import AdvantagesListSkeleton from './view/AdvantagesList/Skeleton';
import AgreementTextSkeleton from './view/AgreementText/Skeleton';
import CookieInfoSkeleton from './view/CookieInfo/Skeleton';
import FeatureDescriptionSkeleton from './view/FeatureDescription/Skeleton';
import FooterSkeleton from './view/Footer/Skeleton';
import HeaderSkeleton from './view/Header/Skeleton';
import MainScreenSkeleton from './view/MainScreen/Skeleton';
import BaseScreenSkeleton from './view/BaseScreen/Skeleton';
import SubscribeScreenSkeleton from './view/SubscribeScreen/Skeleton';

export default new Map([
  ['AdvantagesList', AdvantagesListSkeleton],
  ['AgreementText', AgreementTextSkeleton],
  ['CookieInfo', CookieInfoSkeleton],
  ['FeatureDescription', FeatureDescriptionSkeleton],
  ['Footer', FooterSkeleton],
  ['Header', HeaderSkeleton],
  ['MainScreen', MainScreenSkeleton],
  ['BaseScreen', BaseScreenSkeleton],
  ['SubscribeScreen', SubscribeScreenSkeleton],
]);
