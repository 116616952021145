import React from 'react';
import PropTypes from 'prop-types';

import LazyStaticLayout from 'layout/Static/lazy';
import { ServerErrorPages } from 'pages/Public/Pages';

function ServerErrorRenderer({ lang }) {
  const ServerErrorPage = ServerErrorPages[lang] ?? ServerErrorPages.en;
  return (
    <LazyStaticLayout>
      <ServerErrorPage simpleReload />
    </LazyStaticLayout>
  );
}

ServerErrorRenderer.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default ServerErrorRenderer;
