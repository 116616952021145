import { useMemo } from 'react';

import { DEFAULT_LANG, SUPPORTED_LANG_LIST } from 'constants/common';

export default function usePageLang(pathname) {
  return useMemo(() => {
    const pathnameLang = pathname.match(/^\/[^/]+\//);
    if (!pathnameLang || pathnameLang[0].startsWith('/admin')) {
      if (typeof window === 'undefined') {
        return DEFAULT_LANG;
      }

      const { language } = window.navigator;
      if (!language) {
        return DEFAULT_LANG;
      }

      const prefix = language.split('-')?.[0];
      return SUPPORTED_LANG_LIST.includes(prefix) ? prefix : DEFAULT_LANG;
    }

    const cleanLang = pathnameLang[0].replace(/\//g, '');
    return SUPPORTED_LANG_LIST.includes(cleanLang) ? cleanLang : DEFAULT_LANG;
  }, [pathname]);
}
