export const HELMET_TYPES = {
  TITLE: 'TITLE',
  META_NAME: 'META_NAME',
  META_PROPERTY: 'META_PROPERTY',
  LINK_CANONICAL: 'LINK_CANONICAL',
};

export const HELMET_OPTIONS = {
  [HELMET_TYPES.TITLE]: {
    key: HELMET_TYPES.TITLE,
    value: HELMET_TYPES.TITLE,
    label: '<title>content</title>',
  },
  [HELMET_TYPES.META_NAME]: {
    key: HELMET_TYPES.META_NAME,
    value: HELMET_TYPES.META_NAME,
    label: '<meta name={name} content={content} />',
  },
  [HELMET_TYPES.META_PROPERTY]: {
    key: HELMET_TYPES.META_PROPERTY,
    value: HELMET_TYPES.META_PROPERTY,
    label: '<meta property={property} content={content} />',
  },
  [HELMET_TYPES.LINK_CANONICAL]: {
    key: HELMET_TYPES.LINK_CANONICAL,
    value: HELMET_TYPES.LINK_CANONICAL,
    label: '<link rel="canonical" href={href} />',
  },
};
