export const DEFAULT_LANG = 'en';

export const LANG_MAP = {
  RU: 'ru',
  EN: 'en',
};
export const SUPPORTED_LANG_LIST = Object.values(LANG_MAP);

export const DEFAULT_NOT_FOUND_SLUG = 'not-found-page';

export const VIEW_TYPE_VARIANTS = {
  DARK: 'dark',
  LIGHT: 'light',
  ACCENT: 'accent',
};

export const MENU_TYPES = {
  EXTERNAL_LINK: 'external_link',
  REACT_LINK: 'react_link',
  REACT_HASH_LINK: 'react_hash_link',
  BUTTON: 'button',
  ANCHOR: 'anchor',
  TRIGGER: 'trigger',
};

export const DESKTOP_MENU_OPTIONS = {
  [MENU_TYPES.REACT_LINK]: {
    key: MENU_TYPES.REACT_LINK,
    value: MENU_TYPES.REACT_LINK,
    label: 'Внутренняя ссылка',
  },
  [MENU_TYPES.REACT_HASH_LINK]: {
    key: MENU_TYPES.REACT_HASH_LINK,
    value: MENU_TYPES.REACT_HASH_LINK,
    label: 'Внутренняя ссылка с якорем',
  },
  [MENU_TYPES.EXTERNAL_LINK]: {
    key: MENU_TYPES.EXTERNAL_LINK,
    value: MENU_TYPES.EXTERNAL_LINK,
    label: 'Внешняя ссылка',
  },
  // [MENU_TYPES.BUTTON]: {
  //   key: MENU_TYPES.BUTTON,
  //   value: MENU_TYPES.BUTTON,
  //   label: 'Кнопка с внешней ссылкой',
  // },
  [MENU_TYPES.ANCHOR]: {
    key: MENU_TYPES.ANCHOR,
    value: MENU_TYPES.ANCHOR,
    label: 'Якорь',
  },
  // [MENU_TYPES.TRIGGER]: {
  //   key: MENU_TYPES.TRIGGER,
  //   value: MENU_TYPES.TRIGGER,
  //   label: 'Триггер',
  // },
};

export const MOBILE_MENU_OPTIONS = {
  [MENU_TYPES.REACT_LINK]: {
    key: MENU_TYPES.REACT_LINK,
    value: MENU_TYPES.REACT_LINK,
    label: 'Внутренняя ссылка',
  },
  [MENU_TYPES.REACT_HASH_LINK]: {
    key: MENU_TYPES.REACT_HASH_LINK,
    value: MENU_TYPES.REACT_HASH_LINK,
    label: 'Внутренняя ссылка с якорем',
  },
  [MENU_TYPES.EXTERNAL_LINK]: {
    key: MENU_TYPES.EXTERNAL_LINK,
    value: MENU_TYPES.EXTERNAL_LINK,
    label: 'Внешняя ссылка',
  },
  [MENU_TYPES.ANCHOR]: {
    key: MENU_TYPES.ANCHOR,
    value: MENU_TYPES.ANCHOR,
    label: 'Якорь',
  },
  // [MENU_TYPES.TRIGGER]: {
  //   key: MENU_TYPES.TRIGGER,
  //   value: MENU_TYPES.TRIGGER,
  //   label: 'Триггер',
  // },
};

export const FOOTER_MENU_OPTIONS = {
  [MENU_TYPES.REACT_LINK]: {
    key: MENU_TYPES.REACT_LINK,
    value: MENU_TYPES.REACT_LINK,
    label: 'Внутренняя ссылка',
  },
  [MENU_TYPES.EXTERNAL_LINK]: {
    key: MENU_TYPES.EXTERNAL_LINK,
    value: MENU_TYPES.EXTERNAL_LINK,
    label: 'Внешняя ссылка',
  },
};
