import React, { useMemo } from 'react';
import cls from 'classnames';

import Container from 'components/base/Container';

import TitleSkeleton, {
  TAG_VARIANTS as TITLE_TAGS,
  ALIGN_VARIANTS as TITLE_ALIGN,
} from 'components/atoms/Title/Skeleton';
import ParagraphsSkeleton, {
  ALIGN_VARIANTS as PARAGRAPHS_ALIGN,
} from 'components/atoms/Paragraphs/Skeleton';

import { MIN_DESKTOP_SCREEN, useLayoutContext } from 'utilities/layoutContext';

import { propTypes, defaultProps } from '../propTypes';

import commonStyles from '../Common.module.scss';
import skeletonStyles from './Skeleton.module.scss';
import useIsMounted from '../../../../../hooks/useIsMounted';

function AdvantagesListSkeleton({ list }) {
  const isMounted = useIsMounted();
  const { viewportWidth } = useLayoutContext();

  const isDesktop = useMemo(() => {
    if (!isMounted) {
      return false;
    }

    return viewportWidth >= MIN_DESKTOP_SCREEN;
  }, [isMounted, viewportWidth]);

  return (
    <div className={commonStyles.ComponentRoot}>
      <Container>
        <div className={commonStyles.wrapper}>
          <div className={commonStyles.content}>
            <TitleSkeleton tag={TITLE_TAGS.h2} align={TITLE_ALIGN.CENTER} />
            <div className={commonStyles.carousel}>
              <div className={commonStyles.list}>
                {list.map(({ key, illustration, paddingTop, paragraphs }) => (
                  <div
                    key={key}
                    className={cls(commonStyles.item, skeletonStyles.item)}
                    style={isDesktop && paddingTop ? { paddingTop } : undefined}
                  >
                    {illustration && (
                      <div
                        className={cls(
                          commonStyles.itemIllustration,
                          skeletonStyles.itemIllustration,
                        )}
                      />
                    )}
                    <TitleSkeleton tag={TITLE_TAGS.h4} align={TITLE_ALIGN.CENTER} />
                    <ParagraphsSkeleton
                      className={commonStyles.itemParagraphs}
                      align={PARAGRAPHS_ALIGN.CENTER}
                    >
                      {paragraphs}
                    </ParagraphsSkeleton>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

AdvantagesListSkeleton.propTypes = propTypes;
AdvantagesListSkeleton.defaultProps = defaultProps;

export default AdvantagesListSkeleton;
