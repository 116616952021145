import * as config from 'travelee-config';

export const NODE_ENV_VARIANTS = {
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
};

export function createConfig() {
  // eslint-disable-next-line no-underscore-dangle
  return config.__RUNTIME_ENV__;
}

export default createConfig();
