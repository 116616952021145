import { call, put } from 'redux-saga/effects';

import { coreAxios } from 'utilities/axios';
import requestWithDelay from 'utilities/requestWithDelay';
import errorParser from 'storage/utilities/errorParser';

import { CREATE_ITEM as BASE } from 'constants/adminActions';
import { STAFFS_REDUCER_PATH } from 'constants/reducerPaths';

import { notification } from 'antd';

import { itemChangingActions } from '../slice';

export const CREATE_ITEM = `${STAFFS_REDUCER_PATH}/${BASE}`;

const errorMapper = new Map([
  [
    'email must be an email',
    { type: 'email', message: 'Введенное значение не является email‘ом!' },
  ],
  ['email should not be empty', { type: 'email', message: 'Пожалуйста, заполните это поле!' }],
  [
    'name must be longer than or equal to 0 characters',
    { type: 'name', message: 'Пожалуйста, заполните это поле!' },
  ],
  ['name must be a string', { type: 'name', message: 'Введено некорректное значение!' }],
  ['name should not be empty', { type: 'name', message: 'Пожалуйста, заполните это поле!' }],
  [
    'password must be longer than or equal to 8 characters',
    { type: 'password', message: 'Пожалуйста, заполните это поле!' },
  ],
  ['password must be a string', { type: 'password', message: 'Введено некорректное значение!' }],
  [
    'password should not be empty',
    { type: 'password', message: 'Пожалуйста, заполните это поле!' },
  ],
  [
    'Bad Request',
    {
      type: 'common',
      title: 'Ошибка создания пользователя',
      message: 'Проверьте правильность заполнения полей',
    },
  ],
  [
    'Default user exist',
    [
      {
        type: 'common',
        title: 'Ошибка создания пользователя',
        message: 'Проверьте правильность заполнения полей',
      },
      {
        type: 'email',
        message: 'Пользователь с таким E-mail уже существует!',
      },
    ],
  ],
]);

export function* createItem({ formData }) {
  try {
    itemChangingActions.start();
    errorMapper.set(
      `User with email ${formData.email} already exist`,
      errorMapper.get('Default user exist'),
    );

    yield call(
      requestWithDelay,
      coreAxios({
        url: '/staffs/',
        method: 'POST',
        data: formData,
      }),
    );

    yield put(itemChangingActions.success());
    notification.success({
      key: `create-success-${formData.email}`,
      message: 'Пользователь успешно создан',
      onClick: () => notification.close(`create-success-${formData.email}`),
    });
  } catch (error) {
    yield put(itemChangingActions.error(errorParser({ error, errorMapper })));
  }
}
