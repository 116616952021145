import PropTypes from 'prop-types';

import { COLOR_VARIANTS, SIZES_VARIANTS } from './constants';

export const sizeProp = PropTypes.oneOf(Object.values(SIZES_VARIANTS));

export const propTypes = {
  color: PropTypes.oneOf(Object.values(COLOR_VARIANTS)).isRequired,
  size: sizeProp.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export const defaultProps = {
  disabled: false,
  className: undefined,
};
