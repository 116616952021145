import PropTypes from 'prop-types';
import { ALIGN_VARIANTS, COLOR_VARIANTS, SIZE_VARIANTS } from './constants';

export const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf(Object.values(COLOR_VARIANTS)),
  size: PropTypes.oneOf(Object.values(SIZE_VARIANTS)),
  align: PropTypes.oneOf(Object.values(ALIGN_VARIANTS)),
};

export const defaultProps = {
  className: undefined,
  color: COLOR_VARIANTS.DARK_100,
  size: SIZE_VARIANTS.MEDIUM,
  align: ALIGN_VARIANTS.LEFT,
};
