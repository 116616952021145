import { takeEvery } from 'redux-saga/effects';

import { RESET_TO_INITIAL, resetToInitial } from './actions/resetToInitial';
import { CLEAR_ERROR, clearError } from './actions/clearError';
import { INIT_RELOAD, shouldInitReload } from './actions/initReload';
import { GET_ONE_ITEM, getOneItem } from './actions/getOneItem';
import { COPY_ITEM, copyItem } from './actions/copyItem';
import { UPDATE_ITEM, updateItem } from './actions/updateItem';
import { IMPORT_ITEM, importItem } from './actions/importItem';
import { DELETE_ITEM, deleteItem } from './actions/deleteItem';
import { SWAP_SECTIONS, initSectionsSwap } from './actions/swapSections';
import { REFRESH_CACHE, refreshCache } from './actions/refreshCache';

export default function* pageSagas() {
  yield takeEvery(RESET_TO_INITIAL, resetToInitial);
  yield takeEvery(CLEAR_ERROR, clearError);
  yield takeEvery(INIT_RELOAD, shouldInitReload);
  yield takeEvery(GET_ONE_ITEM, getOneItem);
  yield takeEvery(COPY_ITEM, copyItem);
  yield takeEvery(UPDATE_ITEM, updateItem);
  yield takeEvery(IMPORT_ITEM, importItem);
  yield takeEvery(DELETE_ITEM, deleteItem);
  yield takeEvery(SWAP_SECTIONS, initSectionsSwap);
  yield takeEvery(REFRESH_CACHE, refreshCache);
}
