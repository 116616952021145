import { call, put } from 'redux-saga/effects';
import { coreAxios } from 'utilities/axios';
import requestWithDelay from 'utilities/requestWithDelay';

import { GET_CHILDREN_LIST as BASE } from 'constants/adminActions';
import { BOUNDARIES_FULL_REDUCER_PATH } from 'constants/reducerPaths';

import { childrenActions } from '../slice';

export const GET_CHILDREN_LIST = `${BOUNDARIES_FULL_REDUCER_PATH}/${BASE}`;

export function* getChildrenList({ id, indent }) {
  try {
    yield put(childrenActions.start(id));
    const { data } = yield call(
      requestWithDelay,
      coreAxios({
        url: `/boundaries/${id}/`,
        method: 'GET',
      }),
    );
    const prepared = data.map((item) => ({ ...item, key: item.id, indent }));
    yield put(childrenActions.success({ id, data: prepared }));
  } catch (error) {
    const errorResponse = error?.response?.data ?? error;
    yield put(
      childrenActions.error({
        id,
        error: [
          {
            type: 'common',
            title: errorResponse.statusCode || 'Ошибка сервера',
            message: errorResponse.message || 'Попробуйте выполнить действие позднее',
          },
        ],
      }),
    );
  }
}
