import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

import { VIEW_TYPE_VARIANTS } from 'constants/common';

import styles from './MenuBurger.module.scss';

function MenuBurger({ className, viewType, isOpen, onClick }) {
  return (
    <button
      type="button"
      className={cls(
        styles.MenuBurger,
        styles[`${viewType}MenuBurger`],
        isOpen && styles.openMenuBurger,
        className,
      )}
      onClick={onClick}
      title="Menu toggle"
    >
      <div className={styles.wrapper}>
        <span className={styles.item} />
        <span className={styles.item} />
        <span className={styles.item} />
        <span className={styles.item} />
      </div>
    </button>
  );
}

MenuBurger.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  viewType: PropTypes.oneOf(Object.values(VIEW_TYPE_VARIANTS)).isRequired,
  onClick: PropTypes.func.isRequired,
};

MenuBurger.defaultProps = {
  className: undefined,
};

export default MenuBurger;
