import { call, put } from 'redux-saga/effects';

import { landingsAxios } from 'utilities/axios';
import requestWithDelay from 'utilities/requestWithDelay';
import errorParser from 'storage/utilities/errorParser';

import { UPDATE_ITEM as BASE } from 'constants/adminActions';
import { ROUTES_REDUCER_PATH } from 'constants/reducerPaths';

import { notification } from 'antd';

import { itemChangingActions } from '../../slice';

export const UPDATE_ITEM_WITH_NEW_PAGE = `${ROUTES_REDUCER_PATH}/${BASE}/new`;

const errorMapper = new Map([
  [
    'page.name must be shorter than or equal to 256 characters',
    { type: 'name', message: 'Длина введенного текста не должна превышать 256 символов' },
  ],
  [
    'page.name must be longer than or equal to 0 characters',
    { type: 'name', message: 'Пожалуйста, заполните это поле!' },
  ],
  ['page.name must be a string', { type: 'name', message: 'Введено некорректное значение!' }],
  ['page.name should not be empty', { type: 'name', message: 'Пожалуйста, заполните это поле!' }],
  [
    'Bad Request',
    {
      type: 'common',
      title: 'Ошибка редактирования страницы',
      message: 'Проверьте правильность заполнения полей',
    },
  ],
  [
    'Empty body. Nothing to update',
    [
      {
        type: 'common',
        title: 'Ошибка редактирования блока',
        message: 'Не передано данных для обновления',
      },
    ],
  ],
  [
    'Default not found',
    [{ type: 'common', title: 'Ошибка редактирования', message: 'Страница не найдена' }],
  ],
  [
    'Default route exist',
    [
      {
        type: 'common',
        title: 'Ошибка создания',
        message: 'Проверьте правильность заполнения полей',
      },
      {
        type: 'path',
        message: 'Страница с таким url уже существует!',
      },
    ],
  ],
]);

export function* updateItemWithNewPage({ id, formData }) {
  try {
    itemChangingActions.start();
    errorMapper.set(`Route ${id} not found`, errorMapper.get('Default not found'));
    errorMapper.set(
      `Route with path ${formData.path} already exist`,
      errorMapper.get('Default route exist'),
    );

    const preparedData = {
      ...formData,
      page: formData,
    };

    const { data } = yield call(
      requestWithDelay,
      landingsAxios({
        url: `/routes/${id}/page/`,
        method: 'PATCH',
        data: preparedData,
      }),
    );

    yield put(itemChangingActions.success({ idToOpen: data.page }));
    notification.success({
      key: `update-success-${id}`,
      message: 'Страница успешно обновлена',
      onClick: () => notification.close(`update-success-${id}`),
    });
  } catch (error) {
    yield put(itemChangingActions.error(errorParser({ error, errorMapper })));
  }
}
