import PropTypes from 'prop-types';

import { DIRECTION_VARIANTS, PLACEMENT_VARIANTS } from './constants';

export const propTypes = {
  anchor: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
  paragraphs: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  media: PropTypes.oneOfType([
    () => null,
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      rotate: PropTypes.string,
      shadow: PropTypes.oneOf(Object.values(DIRECTION_VARIANTS)).isRequired,
    }),
  ]),
  illustration: PropTypes.oneOfType([
    () => null,
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      placement: PropTypes.oneOf(Object.values(PLACEMENT_VARIANTS)).isRequired,
      stylesObj: PropTypes.object,
      stopAnimation: PropTypes.bool,
    }),
  ]),
  direction: PropTypes.oneOf(Object.values(DIRECTION_VARIANTS)),
  backgroundImage: PropTypes.oneOfType([() => null, PropTypes.string]),
};

export const defaultProps = {
  paragraphs: '',
  media: null,
  illustration: null,
  direction: DIRECTION_VARIANTS.DIRECT,
  backgroundImage: null,
};
