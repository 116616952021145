import { useMemo } from 'react';

import { SIZES_VARIANTS } from 'components/atoms/Button/constants';

import { useLayoutContext } from 'utilities/layoutContext';

export default function useActionSize(isMounted) {
  const {
    isMobileLayout,
    isMobileWideLayout,
    isTabletLayout,
    isDesktopLayout,
    isDesktopWideLayout,
  } = useLayoutContext();

  return useMemo(() => {
    if (!isMounted) {
      return SIZES_VARIANTS.SMALL;
    }

    switch (true) {
      case isMobileLayout:
      case isMobileWideLayout: {
        return SIZES_VARIANTS.SMALL;
      }
      case isTabletLayout:
      case isDesktopLayout && !isDesktopWideLayout: {
        return SIZES_VARIANTS.MEDIUM;
      }
      default: {
        return SIZES_VARIANTS.LARGE;
      }
    }
  }, [
    isMounted,
    isMobileLayout,
    isMobileWideLayout,
    isTabletLayout,
    isDesktopLayout,
    isDesktopWideLayout,
  ]);
}
