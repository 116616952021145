import { call, put } from 'redux-saga/effects';

import { landingsAxios } from 'utilities/axios';
import requestWithDelay from 'utilities/requestWithDelay';
import errorParser from 'storage/utilities/errorParser';

import { CREATE_ITEM as BASE } from 'constants/adminActions';
import { SECTION_REDUCER_PATH } from 'constants/reducerPaths';

import { notification } from 'antd';

import { INIT_RELOAD as PAGE_INIT_RELOAD } from '../../page/actions/initReload';
import { itemChangingActions } from '../slice';

export const CREATE_ITEM = `${SECTION_REDUCER_PATH}/${BASE}`;

const errorMapper = new Map([
  ['page must be an UUID', { type: 'page', message: 'Введено некорректное значение!' }],
  ['page should not be empty', { type: 'page', message: 'Пожалуйста, заполните это поле!' }],
  ['type must be a string', { type: 'page', message: 'Введено некорректное значение!' }],
  ['type should not be empty', { type: 'page', message: 'Пожалуйста, заполните это поле!' }],
  [
    'position must be an integer number',
    { type: 'page', message: 'Введено некорректное значение!' },
  ],
  ['position should not be empty', { type: 'page', message: 'Пожалуйста, заполните это поле!' }],
  ['props must be an object', { type: 'page', message: 'Введено некорректное значение!' }],
  ['props should not be empty', { type: 'page', message: 'Пожалуйста, заполните это поле!' }],
  [
    'Bad Request',
    {
      type: 'common',
      title: 'Ошибка создания блока',
      message: 'Проверьте правильность заполнения полей',
    },
  ],
  [
    'Default entity not found in PageEntity',
    [
      {
        type: 'common',
        title: 'Ошибка создания блока',
        message: 'Выбранная страница не найдена в базе данных',
      },
    ],
  ],
  [
    'Default sectionType should be active',
    [
      {
        type: 'common',
        title: 'Ошибка создания блока',
        message: 'Проверьте правильность заполнения полей',
      },
      {
        type: 'type',
        message: 'Выбранный блок не является активным!',
      },
    ],
  ],
  [
    'Default entity not found in SectionTypeEntity',
    [
      {
        type: 'common',
        title: 'Ошибка создания блока',
        message: 'Проверьте правильность заполнения полей',
      },
      {
        type: 'type',
        message: 'Выбранный блок не найден в базе данных!',
      },
    ],
  ],
]);

export function* createItem({ formData }) {
  try {
    itemChangingActions.start();
    const { page, type, position, ...props } = formData;
    const data = {
      page,
      position,
      props,
      type: type.split('_')[0],
    };

    errorMapper.set(
      `Entity ${data.type} not found in SectionTypeEntity`,
      errorMapper.get('Default entity not found in SectionTypeEntity'),
    );
    errorMapper.set(
      `SectionType ${data.type} should be active`,
      errorMapper.get('Default sectionType should be active'),
    );
    errorMapper.set(
      `Entity ${data.page} not found in PageEntity`,
      errorMapper.get('Default entity not found in PageEntity'),
    );

    yield call(
      requestWithDelay,
      landingsAxios({
        url: '/sections/items/',
        method: 'POST',
        data,
      }),
    );

    yield put({ type: PAGE_INIT_RELOAD });
    notification.success({
      key: `create-success-${data.type}`,
      message: 'Блок успешно создан',
      onClick: () => notification.close(`create-success-${data.type}`),
    });
  } catch (error) {
    yield put(itemChangingActions.error(errorParser({ error, errorMapper })));
  }
}
