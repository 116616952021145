import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';

import envConfig, { NODE_ENV_VARIANTS } from 'constants/config';

import rootReducer from './modules/reducer';
import rootSagas from './modules/sagas';

export default function createStore(initialState = {}) {
  const sagaMiddleware = createSagaMiddleware();

  const middleware = [
    ...getDefaultMiddleware({ serializableCheck: false, thunk: false }),
    sagaMiddleware,
  ];

  if (envConfig.NODE_ENV === NODE_ENV_VARIANTS.DEVELOPMENT) {
    if (process.env.REACT_APP_REDUX_LOGGER === 'true') {
      middleware.push(logger);
    }
  }

  const store = configureStore({
    middleware,
    reducer: rootReducer,
    preloadedState: initialState,
    devTools: envConfig.NODE_ENV !== NODE_ENV_VARIANTS.PRODUCTION,
  });

  sagaMiddleware.run(rootSagas);

  if (module.hot) {
    module.hot.accept('./modules/reducer', () => {
      store.replaceReducer(rootReducer);
    });
  }

  return store;
}
