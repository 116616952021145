import { call, put } from 'redux-saga/effects';
import { coreAxios } from 'utilities/axios';

import { getSessionActions } from '../slice';

export const GET_USER_SESSION = 'admin/user/GET_USER_SESSION';

export function* getUserSession() {
  try {
    yield put(getSessionActions.start());
    const { data } = yield call(coreAxios, {
      url: '/auth/session/',
      method: 'GET',
    });

    yield put(getSessionActions.success(data));
  } catch (error) {
    yield put(getSessionActions.error());
  }
}
