import { takeEvery } from 'redux-saga/effects';

import { GET_ALL_LIST, getAllList } from './actions/getList';
import { IMPORT_ALL_LIST, importList } from './actions/importList';
import { CREATE_ITEM, createItem } from './actions/createItem';
import { UPDATE_ITEM, updateItem } from './actions/updateItem';
import { DELETE_ITEM, deleteItem } from './actions/deleteItem';
import { CLEAR_ERROR, clearError } from './actions/clearError';
import { RESET_TO_INITIAL, resetToInitial } from './actions/resetToInitial';

export default function* categoriesSagas() {
  yield takeEvery(GET_ALL_LIST, getAllList);
  yield takeEvery(IMPORT_ALL_LIST, importList);
  yield takeEvery(CREATE_ITEM, createItem);
  yield takeEvery(UPDATE_ITEM, updateItem);
  yield takeEvery(DELETE_ITEM, deleteItem);
  yield takeEvery(CLEAR_ERROR, clearError);
  yield takeEvery(RESET_TO_INITIAL, resetToInitial);
}
