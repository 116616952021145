import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

import { ALIGN_VARIANTS, COLOR_VARIANTS, TAG_VARIANTS, SIZE_VARIANTS } from '../constants';
import { propTypes, defaultProps } from '../propTypes';

import commonStyles from '../Common.module.scss';
import skeletonStyles from './Skeleton.module.scss';

const selfClassNames = {
  [TAG_VARIANTS.h1]: skeletonStyles.h1,
  [TAG_VARIANTS.h2]: skeletonStyles.h2,
  [TAG_VARIANTS.h3]: skeletonStyles.h3,
  [TAG_VARIANTS.h4]: skeletonStyles.h4,
  [TAG_VARIANTS.div]: skeletonStyles.div,
};

function TitleSkeleton({ tag, size, color, align, className, contentClassName }) {
  const Tag = tag in TAG_VARIANTS ? tag : TAG_VARIANTS.div;

  return (
    <div className={cls(selfClassNames[size ?? Tag], className)}>
      <Tag
        className={cls(
          commonStyles.content,
          skeletonStyles.content,
          skeletonStyles[`${color}Color`],
          skeletonStyles[`${align}Align`],
          contentClassName,
        )}
      />
    </div>
  );
}

TitleSkeleton.propTypes = {
  contentClassName: PropTypes.string,
  ...propTypes,
};
TitleSkeleton.defaultProps = {
  contentClassName: undefined,
  ...defaultProps,
};

export { ALIGN_VARIANTS, COLOR_VARIANTS, TAG_VARIANTS, SIZE_VARIANTS };
export default TitleSkeleton;
