import { call, put } from 'redux-saga/effects';
import { landingsAxios } from 'utilities/axios';
import requestWithDelay from 'utilities/requestWithDelay';

import { GET_ALL_LIST as BASE } from 'constants/adminActions';
import { PAGES_SHORT_REDUCER_PATH } from 'constants/reducerPaths';

import { allItemActions } from '../slice';

export const GET_ALL_LIST = `${PAGES_SHORT_REDUCER_PATH}/${BASE}`;

export function* getAllList() {
  try {
    yield put(allItemActions.start());
    const { data } = yield call(
      requestWithDelay,
      landingsAxios({
        url: '/pages/',
        method: 'GET',
      }),
    );
    // const prepared = data.map((item, index) => ({ ...item, key: item.id, index: index + 1 }));
    yield put(allItemActions.success(data));
  } catch (error) {
    const errorResponse = error?.response?.data ?? error;
    yield put(
      allItemActions.error([
        {
          type: 'common',
          title: errorResponse.statusCode || 'Ошибка сервера',
          message: errorResponse.message || 'Попробуйте выполнить действие позднее',
        },
      ]),
    );
  }
}
