import { call, put } from 'redux-saga/effects';

import { coreAxios } from 'utilities/axios';
import requestWithDelay from 'utilities/requestWithDelay';
import errorParser from 'storage/utilities/errorParser';

import { UPDATE_ITEM as BASE } from 'constants/adminActions';
import { POINTS_FULL_REDUCER_PATH } from 'constants/reducerPaths';

import { notification } from 'antd';

import { itemChangingActions } from '../slice';
import plainObjectToFormData from '../helpers/plainObjectToFormData';

export const UPDATE_ITEM = `${POINTS_FULL_REDUCER_PATH}/${BASE}`;

const errorMapper = new Map([
  [
    'Bad Request',
    {
      type: 'common',
      title: 'Ошибка обноления точки',
      message: 'Проверьте правильность заполнения полей',
    },
  ],
  [
    'Empty body. Nothing to update',
    [
      {
        type: 'common',
        title: 'Ошибка обноления точки',
        message: 'Не передано данных для обновления',
      },
    ],
  ],
  [
    'Default not found',
    [{ type: 'common', title: 'Ошибка обновления', message: 'Точка не найдена' }],
  ],

  ['name must be an object', { type: 'name', message: 'Пожалуйста, заполните это поле!' }],
  [
    'location should not be empty',
    {
      type: [
        ['location', 'coordinates', '0'],
        ['location', 'coordinates', '1'],
      ],
      message: 'Пожалуйста, заполните это поле!',
    },
  ],
  ['address must be a string', { type: 'address', message: 'Введено некорректное значение!' }],
  ['imageUri must be a string', { type: 'imageUri', message: 'Введено некорректное значение!' }],
  [
    'imageSource must be a string',
    { type: 'imageSource', message: 'Введено некорректное значение!' },
  ],
  ['sourceUri must be a string', { type: 'sourceUri', message: 'Введено некорректное значение!' }],
  [
    'Default category not found',
    [
      {
        type: 'common',
        title: 'Ошибка обноления точки',
        message: 'Проверьте правильность заполнения полей',
      },
      {
        type: 'categories',
        message: 'Выбрана несуществующая категория!',
      },
    ],
  ],
]);

export function* updateItem({ id, formData: { location, ...formData }, initialValues }) {
  try {
    itemChangingActions.start();

    errorMapper.set(`Point ${id} not found`, errorMapper.get('Default not found'));

    if (formData.categories?.length) {
      formData.categories.forEach((category) => {
        errorMapper.set(
          `Category ${category} not found`,
          errorMapper.get('Default category not found'),
        );
      });
    }

    if (formData.name) {
      formData.name = JSON.parse(formData.name);
    }

    const lng = Number.parseFloat(location.coordinates[0]);
    const initialLng = initialValues.location.coordinates[0];

    const lat = Number.parseFloat(location.coordinates[1]);
    const initialLat = initialValues.location.coordinates[1];

    if (lng !== initialLng || lat !== initialLat) {
      formData.location = [];
      formData.location[0] = lng !== initialLng ? lng : initialLng;
      formData.location[1] = lat !== initialLat ? lat : initialLat;
    }

    yield call(
      requestWithDelay,
      coreAxios({
        url: `/points/${id}/`,
        method: 'PATCH',
        data: plainObjectToFormData(formData),
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    );

    yield put(itemChangingActions.success());
    notification.success({
      key: `update-success-${id}`,
      message: 'Точка успешно изменена',
      onClick: () => notification.close(`update-success-${id}`),
    });
  } catch (error) {
    yield put(itemChangingActions.error(errorParser({ error, errorMapper })));
  }
}
