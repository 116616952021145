import PropTypes from 'prop-types';
import _omit from 'lodash/omit';

import { MENU_TYPES } from 'constants/common';

export const itemPropTypes = {
  sendEventCreator: PropTypes.func.isRequired,
  type: PropTypes.oneOf([MENU_TYPES.REACT_LINK, MENU_TYPES.EXTERNAL_LINK]).isRequired,
  text: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  icon: PropTypes.string,
  tooltip: PropTypes.string,
  eventData: PropTypes.object,
};

export const itemDefaultProps = {
  icon: '',
  tooltip: '',
  eventData: {},
};

export const propTypes = {
  leftGroup: PropTypes.arrayOf(
    PropTypes.shape({
      ..._omit(itemPropTypes, ['sendEventCreator']),
      key: PropTypes.string.isRequired,
    }),
  ),
  rightGroup: PropTypes.arrayOf(
    PropTypes.shape({
      ..._omit(itemPropTypes, ['sendEventCreator']),
      key: PropTypes.string.isRequired,
    }),
  ),
  text: PropTypes.node,
  showAppLinks: PropTypes.bool,
  appLinks: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
    }),
  ),
};

export const defaultProps = {
  leftGroup: [],
  rightGroup: [],
  text: '',
  showAppLinks: false,
  appLinks: [],
};
