export default function errorParser({ error, errorMapper = new Map() }) {
  const errorResponse = error?.response?.data ?? error;

  if (Array.isArray(errorResponse.message)) {
    const errors = errorResponse.message.map(
      (message) => errorMapper.get(message) ?? { type: 'other', message },
    );
    const commonError = errorMapper.get(errorResponse.error) ?? {};
    return [...errors, commonError];
  }

  return (
    errorMapper.get(errorResponse.message) ?? [
      {
        type: 'common',
        title: errorResponse.statusCode || 'Ошибка сервера',
        message: errorResponse.message || 'Попробуйте выполнить действие позднее',
      },
    ]
  );
}
