export const COLOR_VARIANTS = {
  BASE_DARK: 'baseDark',
  NEUTRAL: 'neutral',
  OUTLINED: 'outlined',
  TRANSPARENT: 'transparent',
  // PRIMARY: 'primary',
};

export const TYPE_VARIANTS = {
  BUTTON: 'button',
  SUBMIT: 'submit',
  RESET: 'reset',
};

export const SIZES_VARIANTS = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

export const TARGET_VARIANTS = {
  SELF: '_self',
  BLANK: '_blank',
  PARENT: '_parent',
  TOP: '_top',
};
