const REQUEST_LOADING_MIN_TIME = 400;

export default async function uxWaitRequest(promise) {
  if (typeof window === 'undefined') {
    return promise;
  }

  const [requestPromise] = await Promise.all([
    promise,
    new Promise((resolve) => setTimeout(resolve, REQUEST_LOADING_MIN_TIME)),
  ]);
  return requestPromise;
}
