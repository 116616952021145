import { call, put } from 'redux-saga/effects';

import { landingsAxios } from 'utilities/axios';
import requestWithDelay from 'utilities/requestWithDelay';
import errorParser from 'storage/utilities/errorParser';

import { CREATE_ITEM as BASE } from 'constants/adminActions';
import { PAGES_FULL_REDUCER_PATH } from 'constants/reducerPaths';

import { notification } from 'antd';

import { itemChangingActions } from '../slice';

export const CREATE_ITEM = `${PAGES_FULL_REDUCER_PATH}/${BASE}`;

const errorMapper = new Map([
  ['name must be a string', { type: 'name', message: 'Введено некорректное значение!' }],
  ['name should not be empty', { type: 'name', message: 'Пожалуйста, заполните это поле!' }],
  ['slug must be a string', { type: 'slug', message: 'Введено некорректное значение!' }],
  ['slug should not be empty', { type: 'slug', message: 'Пожалуйста, заполните это поле!' }],
  [
    'Bad Request',
    {
      type: 'common',
      title: 'Ошибка создания страницы',
      message: 'Проверьте правильность заполнения полей',
    },
  ],
  [
    'Default page slug exist',
    [
      {
        type: 'common',
        title: 'Ошибка редактирования страницы',
        message: 'Проверьте правильность заполнения полей',
      },
      {
        type: 'slug',
        message: 'Страница с таким slug уже существует!',
      },
    ],
  ],
]);

export function* createItem({ formData }) {
  try {
    itemChangingActions.start();
    errorMapper.set(
      `Page with slug "${formData.slug}" already exist`,
      errorMapper.get('Default page slug exist'),
    );

    yield call(
      requestWithDelay,
      landingsAxios({
        url: '/pages/',
        method: 'POST',
        data: formData,
      }),
    );

    yield put(itemChangingActions.success());
    notification.success({
      key: `create-success-${formData.slug}`,
      message: 'Страница успешно создана',
      onClick: () => notification.close(`create-success-${formData.slug}`),
    });
  } catch (error) {
    yield put(itemChangingActions.error(errorParser({ error, errorMapper })));
  }
}
