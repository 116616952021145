import { createSlice } from '@reduxjs/toolkit';

const commonSlice = createSlice({
  name: 'common',
  initialState: {
    showDonateModal: false,
  },
  reducers: {
    toggleDonateModal(state, action) {
      state.showDonateModal = action.payload;
    },
  },
});

export const { toggleDonateModal } = commonSlice.actions;

export default commonSlice.reducer;
