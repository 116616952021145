import { call, put } from 'redux-saga/effects';

import { landingsAxios } from 'utilities/axios';
import requestWithDelay from 'utilities/requestWithDelay';
import errorParser from 'storage/utilities/errorParser';

import { CREATE_ITEM as BASE } from 'constants/adminActions';
import { ROUTES_REDUCER_PATH } from 'constants/reducerPaths';

import { notification } from 'antd';

import { itemChangingActions } from '../../slice';

export const CREATE_ITEM_WITH_EXISTING_PAGE = `${ROUTES_REDUCER_PATH}/${BASE}/exist`;

const errorMapper = new Map([
  ['page must be an UUID', { type: 'page', message: 'Введено некорректное значение!' }],
  ['page should not be empty', { type: 'page', message: 'Пожалуйста, заполните это поле!' }],
  ['path must be a string', { type: 'path', message: 'Введено некорректное значение!' }],
  ['path should not be empty', { type: 'path', message: 'Пожалуйста, заполните это поле!' }],
  [
    'Bad Request',
    {
      type: 'common',
      title: 'Ошибка создания страницы',
      message: 'Проверьте правильность заполнения полей',
    },
  ],
  [
    'Default route exist',
    [
      {
        type: 'common',
        title: 'Ошибка создания страницы',
        message: 'Проверьте правильность заполнения полей',
      },
      {
        type: 'path',
        message: 'Страница с таким url уже существует!',
      },
    ],
  ],
]);

export function* createWithExistingPage({ formData }) {
  try {
    itemChangingActions.start();
    errorMapper.set(
      `Route "${formData.path}" already exist`,
      errorMapper.get('Default route exist'),
    );

    const { data } = yield call(
      requestWithDelay,
      landingsAxios({
        url: '/routes/',
        method: 'POST',
        data: formData,
      }),
    );

    yield put(itemChangingActions.success({ idToOpen: data.page.id }));
    notification.success({
      key: `create-success-${formData.path}`,
      message: 'Страница успешно создана',
      onClick: () => notification.close(`create-success-${formData.path}`),
    });
  } catch (error) {
    yield put(itemChangingActions.error(errorParser({ error, errorMapper })));
  }
}
