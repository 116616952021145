const status = {
  initial: false,
  loading: false,
  success: false,
  error: false,
  cancelled: false,
};

export const initial = () => ({ ...status, initial: true });
export const begin = () => ({ ...status, loading: true });
export const success = () => ({ ...status, success: true });
export const error = (e = true) => ({ ...status, error: e });
export const cancelled = () => ({ ...status, cancelled: true });
export const clear = () => status;

export const withData = {
  initial(data = undefined) {
    return {
      ...initial(),
      data,
    };
  },
  begin(data = undefined) {
    return {
      ...begin(),
      data,
    };
  },
  success(data) {
    return {
      ...success(),
      data,
    };
  },
  error(e) {
    return {
      ...error(e),
      data: undefined,
    };
  },
  clear() {
    return {
      ...clear(),
      data: undefined,
    };
  },
};
