import { put } from 'redux-saga/effects';
import { CLEAR_ERROR as BASE } from 'constants/adminActions';
import { PAGE_REDUCER_PATH } from 'constants/reducerPaths';

import { clearErrorStatus } from '../slice';

export const CLEAR_ERROR = `${PAGE_REDUCER_PATH}/${BASE}`;

export function* clearError() {
  yield put(clearErrorStatus());
}
