import { createSlice } from '@reduxjs/toolkit';

import * as BASE_LOADABLE from 'storage/utilities/baseLoadable';

const routesSlice = createSlice({
  name: 'routes',
  initialState: {
    statuses: BASE_LOADABLE.initial(),
    data: [],
    error: null,
  },
  reducers: {
    startLoading: (state) => {
      state.statuses = BASE_LOADABLE.begin();
    },
    successLoaded: (state, action) => {
      state.statuses = BASE_LOADABLE.success();
      state.data = action.payload;
      state.error = null;
    },
    loadedWithError: (state, action) => {
      state.statuses = BASE_LOADABLE.success();
      state.data = [];
      state.error = action.payload;
    },
  },
});

const { startLoading, successLoaded, loadedWithError } = routesSlice.actions;

export const fetchRoutesActions = {
  startLoading,
  successLoaded,
  loadedWithError,
};

export default routesSlice.reducer;
