import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import StaticLayout from 'layout/Static';

function StaticRoute({ component: Component, slug, withLang, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <StaticLayout>
          <Component slug={slug} withLang={withLang} {...props} />
        </StaticLayout>
      )}
    />
  );
}

StaticRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.element, PropTypes.object]).isRequired,
  slug: PropTypes.string,
  withLang: PropTypes.bool,
};

StaticRoute.defaultProps = {
  slug: undefined,
  withLang: undefined,
};

export default StaticRoute;
