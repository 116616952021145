import React from 'react';

import Container from 'components/base/Container';

import TitleSkeleton, {
  ALIGN_VARIANTS as TITLE_ALIGNS,
  TAG_VARIANTS as TITLE_TAGS,
} from 'components/atoms/Title/Skeleton';
import ParagraphsSkeleton, {
  ALIGN_VARIANTS as PARAGRAPHS_ALIGNS,
} from 'components/atoms/Paragraphs/Skeleton';

import SubscribeFormSkeleton from 'components/organisms/SubscribeForm/Skeleton';

import useIsMounted from 'hooks/useIsMounted';

import useActionSize from '../../hooks/useActionSize';

import { propTypes, defaultProps } from '../propTypes';

import commonStyles from '../Common.module.scss';

function SubscribeScreenSkeleton({ paragraphs, form }) {
  const isMounted = useIsMounted();
  const actionSize = useActionSize(isMounted);

  return (
    <div className={commonStyles.ComponentRoot}>
      <Container>
        <div className={commonStyles.wrapper}>
          <div className={commonStyles.content}>
            <TitleSkeleton tag={TITLE_TAGS.h3} align={TITLE_ALIGNS.CENTER} />
            {paragraphs && (
              <ParagraphsSkeleton
                className={commonStyles.description}
                align={PARAGRAPHS_ALIGNS.CENTER}
              >
                {paragraphs}
              </ParagraphsSkeleton>
            )}
            <SubscribeFormSkeleton
              className={commonStyles.form}
              actionSize={actionSize}
              {...form}
            />
          </div>
        </div>
      </Container>
    </div>
  );
}

SubscribeScreenSkeleton.propTypes = propTypes;
SubscribeScreenSkeleton.defaultProps = defaultProps;

export default SubscribeScreenSkeleton;
