import { put } from 'redux-saga/effects';
import { RESET_TO_INITIAL as BASE } from 'constants/adminActions';
import { PAGE_REDUCER_PATH } from 'constants/reducerPaths';

import { resetStateToInitial } from '../slice';

export const RESET_TO_INITIAL = `${PAGE_REDUCER_PATH}/${BASE}`;

export function* resetToInitial() {
  yield put(resetStateToInitial());
}
