import { call, put } from 'redux-saga/effects';

import { landingsAxios } from 'utilities/axios';
import requestWithDelay from 'utilities/requestWithDelay';
import errorParser from 'storage/utilities/errorParser';

import { REFRESH_CACHE as BASE } from 'constants/adminActions';
import { PAGE_REDUCER_PATH } from 'constants/reducerPaths';

import { itemChangingActions } from '../slice';

export const REFRESH_CACHE = `${PAGE_REDUCER_PATH}/${BASE}`;

const errorMapper = new Map([
  [
    'Cache refresh available only on Production env',
    [
      {
        type: 'common',
        title: 'Ошибка обновления кеша страницы',
        message: 'Кеш возможно обновить только в production-окружении.',
      },
    ],
  ],
  [
    'Page not found',
    [
      {
        type: 'common',
        title: 'Ошибка обновления кеша страницы',
        message: 'Страница не найдена в базе данных.',
      },
    ],
  ],
  [
    'Error on server side rendering. See logs',
    [
      {
        type: 'common',
        title: 'Ошибка обновления кеша страницы',
        message: 'Ошибка выполнения ssr-скрипта. Проверьте логи.',
      },
    ],
  ],
  [
    'Unknown error',
    [
      {
        type: 'common',
        title: 'Ошибка обновления кеша страницы',
        message: 'Неизвестная ошибка при обновлении кеша. Проверьте логи.',
      },
    ],
  ],
]);

export function* refreshCache({ url, meta }) {
  try {
    yield call(
      requestWithDelay,
      landingsAxios({
        url: '/ssr/refresh-cache/',
        method: 'POST',
        data: { url },
      }),
    );

    yield call(meta.resolve);
  } catch (error) {
    yield put(itemChangingActions.error(errorParser({ error, errorMapper })));
    yield call(meta.reject);
  }
}
