import React from 'react';

import Container from 'components/base/Container';

import TitleSkeleton, {
  TAG_VARIANTS as TITLE_TAGS,
  SIZE_VARIANTS as TITLE_SIZES,
  ALIGN_VARIANTS as TITLE_ALIGNS,
} from 'components/atoms/Title/Skeleton';
import ParagraphsSkeleton, {
  SIZE_VARIANTS as PARAGRAPHS_SIZES,
  COLOR_VARIANTS as PARAGRAPHS_COLORS,
} from 'components/atoms/Paragraphs/Skeleton';

import { propTypes, defaultProps } from '../propTypes';

import commonStyles from '../Common.module.scss';

function AgreementTextSkeleton({ description, rules }) {
  return (
    <div className={commonStyles.ComponentRoot}>
      <Container>
        <div className={commonStyles.wrapper}>
          <TitleSkeleton tag={TITLE_TAGS.h1} size={TITLE_SIZES.div} align={TITLE_ALIGNS.CENTER} />
          {description && (
            <ParagraphsSkeleton
              className={commonStyles.description}
              size={PARAGRAPHS_SIZES.SMALL}
              color={PARAGRAPHS_COLORS.DARK_200}
            >
              {description}
            </ParagraphsSkeleton>
          )}
          {Boolean(rules.length) && (
            <ol className={commonStyles.rules}>
              {rules.map(({ subList = [], subText = '', ...rule }) => (
                <li key={rule.key} className={commonStyles.ruleItem}>
                  <ParagraphsSkeleton
                    className={commonStyles.ruleText}
                    size={PARAGRAPHS_SIZES.SMALL}
                    color={PARAGRAPHS_COLORS.DARK_400}
                  >
                    {rule.text}
                  </ParagraphsSkeleton>
                  {subText && (
                    <ParagraphsSkeleton
                      className={commonStyles.ruleSubText}
                      size={PARAGRAPHS_SIZES.SMALL}
                      color={PARAGRAPHS_COLORS.DARK_200}
                    >
                      {subText}
                    </ParagraphsSkeleton>
                  )}
                  {Boolean(subList.length) && (
                    <ol className={commonStyles.subRules}>
                      {subList.map((subRule) => (
                        <li key={subRule.key} className={commonStyles.subRuleItem}>
                          <ParagraphsSkeleton
                            className={commonStyles.subRuleText}
                            size={PARAGRAPHS_SIZES.SMALL}
                            color={PARAGRAPHS_COLORS.DARK_200}
                          >
                            {subRule.text}
                          </ParagraphsSkeleton>
                        </li>
                      ))}
                    </ol>
                  )}
                </li>
              ))}
            </ol>
          )}
        </div>
      </Container>
    </div>
  );
}

AgreementTextSkeleton.propTypes = propTypes;
AgreementTextSkeleton.defaultProps = defaultProps;

export default AgreementTextSkeleton;
