import { call, put } from 'redux-saga/effects';

import { landingsAxios } from 'utilities/axios';
import requestWithDelay from 'utilities/requestWithDelay';
import errorParser from 'storage/utilities/errorParser';

import { DELETE_ITEM as BASE } from 'constants/adminActions';
import { PAGE_REDUCER_PATH } from 'constants/reducerPaths';

import { INIT_RELOAD as ROUTES_INIT_RELOAD } from '../../routes/actions/initReload';
import { INIT_RELOAD as PAGES_INIT_RELOAD } from '../../pages/full/actions/initReload';
import { itemChangingActions } from '../slice';

export const DELETE_ITEM = `${PAGE_REDUCER_PATH}/${BASE}`;

const errorMapper = new Map([
  [
    'Default not found',
    [{ type: 'common', title: 'Ошибка удаления', message: 'Страница не найдена' }],
  ],
]);

export function* deleteItem({ id, meta }) {
  try {
    errorMapper.set(`Page ${id} not found`, errorMapper.get('Default not found'));

    yield call(
      requestWithDelay,
      landingsAxios({
        url: `/pages/${id}/`,
        method: 'DELETE',
      }),
    );

    yield call(meta.resolve);
    yield put({ type: ROUTES_INIT_RELOAD });
    yield put({ type: PAGES_INIT_RELOAD });
  } catch (error) {
    yield put(itemChangingActions.error(errorParser({ error, errorMapper })));
    yield call(meta.reject);
  }
}
