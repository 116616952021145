import { call, put } from 'redux-saga/effects';

import _omit from 'lodash/omit';
import { landingsAxios } from 'utilities/axios';
import requestWithDelay from 'utilities/requestWithDelay';
import errorParser from 'storage/utilities/errorParser';

import { UPDATE_ITEM as BASE } from 'constants/adminActions';
import { SECTION_REDUCER_PATH } from 'constants/reducerPaths';

import { notification } from 'antd';

import { INIT_RELOAD as PAGE_INIT_RELOAD } from '../../page/actions/initReload';
import { itemChangingActions } from '../slice';

export const UPDATE_ITEM = `${SECTION_REDUCER_PATH}/${BASE}`;

const errorMapper = new Map([
  [
    'Bad Request',
    {
      type: 'common',
      title: 'Ошибка редактирования блока',
      message: 'Проверьте правильность заполнения полей',
    },
  ],
  [
    'Empty body. Nothing to update',
    [
      {
        type: 'common',
        title: 'Ошибка редактирования блока',
        message: 'Не передано данных для обновления',
      },
    ],
  ],
  [
    'Default not found',
    [{ type: 'common', title: 'Ошибка редактирования', message: 'Блок не найден' }],
  ],
  [
    'Default entity not found in PageEntity',
    [
      {
        type: 'common',
        title: 'Ошибка создания блока',
        message: 'Выбранная страница не найдена в базе данных',
      },
    ],
  ],
  [
    'Default sectionType should be active',
    [
      {
        type: 'common',
        title: 'Ошибка создания блока',
        message: 'Проверьте правильность заполнения полей',
      },
      {
        type: 'type',
        message: 'Выбранный блок не является активным!',
      },
    ],
  ],
  [
    'Default entity not found in SectionTypeEntity',
    [
      {
        type: 'common',
        title: 'Ошибка создания блока',
        message: 'Проверьте правильность заполнения полей',
      },
      {
        type: 'type',
        message: 'Выбранный блок не найден в базе данных!',
      },
    ],
  ],
]);

export function* updateItem({ id, formData, initialValues }) {
  try {
    itemChangingActions.start();

    const { type, ...props } = _omit(formData, ['page', 'position']);
    const data = {
      props,
    };
    if (type !== initialValues.type) {
      [data.type] = type.split('_');
    }

    errorMapper.set(`SectionItem ${id} not found`, errorMapper.get('Default not found'));
    errorMapper.set(
      `Entity ${formData.type} not found in SectionTypeEntity`,
      errorMapper.get('Default entity not found in SectionTypeEntity'),
    );
    errorMapper.set(
      `SectionType ${formData.type} should be active`,
      errorMapper.get('Default sectionType should be active'),
    );
    errorMapper.set(
      `Entity ${formData.page} not found in PageEntity`,
      errorMapper.get('Default entity not found in PageEntity'),
    );

    yield call(
      requestWithDelay,
      landingsAxios({
        url: `/sections/items/${id}/`,
        method: 'PATCH',
        data,
      }),
    );

    yield put({ type: PAGE_INIT_RELOAD });
    notification.success({
      key: `update-success-${id}`,
      message: 'Блок успешно изменен',
      onClick: () => notification.close(`update-success-${id}`),
    });
  } catch (error) {
    yield put(itemChangingActions.error(errorParser({ error, errorMapper })));
  }
}
