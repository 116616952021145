export const GET_ALL_LIST = 'GET_ALL_LIST';
export const GET_CHILDREN_LIST = 'GET_CHILDREN_LIST';
export const GET_ONE_ITEM = 'GET_ONE_ITEM';
export const IMPORT_ALL_LIST = 'IMPORT_ALL_LIST';
export const CREATE_ITEM = 'CREATE_ITEM';
export const UPDATE_ITEM = 'UPDATE_ITEM';
export const IMPORT_ITEM = 'IMPORT_ITEM';
export const SWAP_ITEMS = 'SWAP_ITEMS';
export const DELETE_ITEM = 'DELETE_ITEM';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const RESET_TO_INITIAL = 'RESET_TO_INITIAL';
export const CLEAR_ID_TO_OPEN = 'CLEAR_ID_TO_OPEN';
export const INIT_RELOAD = 'INIT_RELOAD';
export const REFRESH_CACHE = 'REFRESH_CACHE';
