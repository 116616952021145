import { call, put } from 'redux-saga/effects';

import { coreAxios } from 'utilities/axios';
import requestWithDelay from 'utilities/requestWithDelay';
import errorParser from 'storage/utilities/errorParser';

import { CREATE_ITEM as BASE } from 'constants/adminActions';
import { CATEGORIES_FULL_REDUCER_PATH } from 'constants/reducerPaths';
import { EmptyCategoryParentKey } from 'constants/keys';

import { notification } from 'antd';

import { itemChangingActions } from '../slice';

export const CREATE_ITEM = `${CATEGORIES_FULL_REDUCER_PATH}/${BASE}`;

const errorMapper = new Map([
  ['name must be an object', { type: 'name', message: 'Пожалуйста, заполните это поле!' }],
  [
    'slug must be longer than or equal to 0 characters',
    { type: 'slug', message: 'Пожалуйста, заполните это поле!' },
  ],
  ['slug must be a string', { type: 'slug', message: 'Введено некорректное значение!' }],
  ['slug should not be empty', { type: 'slug', message: 'Пожалуйста, заполните это поле!' }],
  [
    'Bad Request',
    {
      type: 'common',
      title: 'Ошибка создания категории',
      message: 'Проверьте правильность заполнения полей',
    },
  ],
  [
    'Default category exist',
    [
      {
        type: 'common',
        title: 'Ошибка создания категории',
        message: 'Проверьте правильность заполнения полей',
      },
      {
        type: 'slug',
        message: 'Категория с таким slug уже существует!',
      },
    ],
  ],
  [
    'Default parent not exist',
    [
      {
        type: 'common',
        title: 'Ошибка создания категории',
        message: 'Проверьте правильность заполнения полей',
      },
      {
        type: 'parentId',
        message: 'Родительской категории не существует!',
      },
    ],
  ],
]);

export function* createItem({ formData }) {
  try {
    itemChangingActions.start();

    errorMapper.set(
      `Category with slug ${formData.slug} already exist`,
      errorMapper.get('Default category exist'),
    );

    formData.name = JSON.parse(formData.name);
    if (formData.parentId) {
      formData.parent = formData.parentId !== EmptyCategoryParentKey ? formData.parentId : null;
    }
    if (formData.parentId) {
      errorMapper.set(
        `Parent boundary ${formData.parent} not found`,
        errorMapper.get('Default parent not exist'),
      );
    }

    yield call(
      requestWithDelay,
      coreAxios({
        url: '/categories/',
        method: 'POST',
        data: formData,
      }),
    );

    yield put(itemChangingActions.success());
    notification.success({
      key: `create-success-${formData.slug}`,
      message: 'Категория успешно создана',
      onClick: () => notification.close(`create-success-${formData.slug}`),
    });
  } catch (error) {
    yield put(itemChangingActions.error(errorParser({ error, errorMapper })));
  }
}
