export const PAGE_REDUCER_PATH = 'admin.page';
export const PAGES_FULL_REDUCER_PATH = 'admin.pages.full';
export const PAGES_SHORT_REDUCER_PATH = 'admin.pages.short';

export const SECTION_REDUCER_PATH = 'admin.section';
export const SECTION_TYPES_REDUCER_PATH = 'admin.sectionTypes';
export const SECTION_TYPES_LIST_REDUCER_PATH = 'admin.sectionTypesList';

export const ROUTES_REDUCER_PATH = 'admin.routes';

export const STAFFS_REDUCER_PATH = 'admin.staffs';
export const USERS_REDUCER_PATH = 'admin.users';

export const CATEGORIES_FULL_REDUCER_PATH = 'admin.categories.full';
export const CATEGORIES_SHORT_REDUCER_PATH = 'admin.categories.short';

export const BOUNDARIES_FULL_REDUCER_PATH = 'admin.boundaries.full';
export const BOUNDARIES_SHORT_REDUCER_PATH = 'admin.boundaries.short';
export const BOUNDARIES_SINGLE_BORDER_REDUCER_PATH = 'admin.boundaries.single.border';
export const BOUNDARIES_SINGLE_TAGS_REDUCER_PATH = 'admin.boundaries.single.tags';

export const POINTS_FULL_REDUCER_PATH = 'admin.points.full';
export const POINTS_SINGLE_TAGS_REDUCER_PATH = 'admin.points.single.tags';
