import { combineReducers } from '@reduxjs/toolkit';

import user from './user';
import staffs from './staffs';

import users from './users';
import categories from './categories';
import boundaries from './boundaries';
import points from './points';

import routes from './routes';
import pages from './pages';
import page from './page';
import section from './section';
import sectionTypes from './sectionTypes';
import sectionTypesList from './sectionTypesList';

export default combineReducers({
  user,
  staffs,

  users,
  categories,
  boundaries,
  points,

  routes,
  pages,
  page,
  section,
  sectionTypes,
  sectionTypesList,
});
