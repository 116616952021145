import React from 'react';
import PropTypes from 'prop-types';

import Helmet from 'react-helmet';

import { useLocation } from 'react-router-dom';
import usePageLang from 'hooks/usePageLang';

import { HELMET_TYPES } from './constants';

function DynamicHelmet({ seoList }) {
  const { pathname } = useLocation();
  const lang = usePageLang(pathname);

  if (!seoList.length) {
    return null;
  }

  return (
    <Helmet htmlAttributes={{ lang }}>
      {seoList.map((item) => {
        if (item.type === HELMET_TYPES.TITLE) {
          return <title key={item.key}>{item.content}</title>;
        }

        if (item.type === HELMET_TYPES.META_NAME) {
          return <meta key={item.key} name={item.name} content={item.content} />;
        }

        if (item.type === HELMET_TYPES.META_PROPERTY) {
          return <meta key={item.key} property={item.property} content={item.content} />;
        }

        if (item.type === HELMET_TYPES.LINK_CANONICAL) {
          return <link key={item.key} rel="canonical" href={item.href} />;
        }

        return null;
      })}
    </Helmet>
  );
}

DynamicHelmet.propTypes = {
  seoList: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.exact({
        key: PropTypes.string.isRequired,
        type: PropTypes.oneOf([HELMET_TYPES.TITLE]).isRequired,
        content: PropTypes.string.isRequired,
      }),
      PropTypes.exact({
        key: PropTypes.string.isRequired,
        type: PropTypes.oneOf([HELMET_TYPES.META_NAME]).isRequired,
        name: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
      }),
      PropTypes.exact({
        key: PropTypes.string.isRequired,
        type: PropTypes.oneOf([HELMET_TYPES.META_PROPERTY]).isRequired,
        property: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
      }),
      PropTypes.exact({
        key: PropTypes.string.isRequired,
        type: PropTypes.oneOf([HELMET_TYPES.LINK_CANONICAL]).isRequired,
        href: PropTypes.string.isRequired,
      }),
    ]),
  ),
};

DynamicHelmet.defaultProps = {
  seoList: [],
};

export default DynamicHelmet;
