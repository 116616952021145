import { call, put } from 'redux-saga/effects';
import { coreAxios } from 'utilities/axios';
import requestWithDelay from 'utilities/requestWithDelay';

import { GET_ALL_LIST as BASE } from 'constants/adminActions';
import { CATEGORIES_FULL_REDUCER_PATH } from 'constants/reducerPaths';

import { allItemActions } from '../slice';

export const GET_ALL_LIST = `${CATEGORIES_FULL_REDUCER_PATH}/${BASE}`;

function mapToArray({ category, children: childrenIds }, map) {
  const item = { key: category.id, ...category };

  if (childrenIds.length === 0) {
    return item;
  }

  const children = childrenIds
    .map((id) => mapToArray(map.get(id), map))
    .sort((childA, childB) => {
      if (childA.slug > childB.slug) {
        return 1;
      }
      if (childA.slug < childB.slug) {
        return -1;
      }
      return 0;
    });
  return { ...item, children };
}

function prepareCategoriesTree(plainCategories) {
  const { map, initial } = plainCategories.reduce(
    (acc, category) => {
      const inMapParent = acc.map.get(category.parentId);
      if (inMapParent !== undefined) {
        acc.map.set(category.parentId, {
          ...inMapParent,
          children: [...inMapParent.children, category.id],
        });
      } else {
        acc.map.set(category.parentId, {
          category: null,
          children: [category.id],
        });
      }

      const inMapCategory = acc.map.get(category.id);
      if (inMapCategory !== undefined && inMapCategory.category != null) {
        return acc;
      }

      acc.map.set(category.id, {
        category,
        children: inMapCategory?.children ?? [],
      });
      if (category.parentId == null) {
        acc.initial.push(category.id);
      }
      return acc;
    },
    { map: new Map(), initial: [] },
  );

  if (map.size === 0 && initial.length === 0) {
    return [];
  }

  return mapToArray({ category: {}, children: initial }, map).children;
}

export function* getAllList() {
  try {
    yield put(allItemActions.start());
    const { data } = yield call(
      requestWithDelay,
      coreAxios({
        url: '/categories/',
        method: 'GET',
      }),
    );
    yield put(allItemActions.success({ data: prepareCategoriesTree(data), plain: data }));
  } catch (error) {
    const errorResponse = error?.response?.data ?? error;
    yield put(
      allItemActions.error([
        {
          type: 'common',
          title: errorResponse.statusCode || 'Ошибка сервера',
          message: errorResponse.message || 'Попробуйте выполнить действие позднее',
        },
      ]),
    );
  }
}
