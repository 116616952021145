import { createSlice } from '@reduxjs/toolkit';
import _isEqual from 'lodash/isEqual';

import * as BASE_LOADABLE from 'storage/utilities/baseLoadable';

const markupsSlice = createSlice({
  name: 'markups',
  initialState: {},
  reducers: {
    startLoading: (state, action) => {
      const { id } = action.payload;
      state[id] = { statuses: BASE_LOADABLE.begin(), data: null, error: null };
    },
    successLoaded: (state, action) => {
      const { id, data } = action.payload;
      state[id] = { statuses: BASE_LOADABLE.success(), data, error: null };
    },
    loadedWithError: (state, action) => {
      const { id, error } = action.payload;
      state[id] = { statuses: BASE_LOADABLE.error(), data: null, error };
    },
    successLoadedWithCache: (state, action) => {
      const { id, data } = action.payload;
      const fromStateMarkup = state?.[id].data;
      if (_isEqual(data, fromStateMarkup)) {
        return;
      }
      state[id] = { statuses: BASE_LOADABLE.success(), data, error: null };
    },
  },
});

export const fetchMarkupWithoutActions = {
  startLoading: markupsSlice.actions.startLoading,
  successLoaded: markupsSlice.actions.successLoaded,
  loadedWithError: markupsSlice.actions.loadedWithError,
};

export const fetchMarkupWithCacheActions = {
  successLoaded: markupsSlice.actions.successLoadedWithCache,
};

export default markupsSlice.reducer;
