import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import LazyAdminLayout from 'layout/Admin/lazy';
import Loader from 'components/organisms/Loader';

import { GET_USER_SESSION } from 'storage/modules/admin/user/actions/session';

import { STAFF_ROLES } from 'constants/auth';

export const BASE_ADMIN_PATH = '/admin';

export const ADMIN_PATHS = {
  LOGIN_PAGE: `${BASE_ADMIN_PATH}/`,
  STAFFS_PAGE: `${BASE_ADMIN_PATH}/staffs/`,

  USERS_PAGE: `${BASE_ADMIN_PATH}/users/`,
  CATEGORIES_PAGE: `${BASE_ADMIN_PATH}/categories/`,
  BOUNDARIES_PAGE: `${BASE_ADMIN_PATH}/boundaries/`,
  POINTS_PAGE: `${BASE_ADMIN_PATH}/points/`,
  COLLECTIONS_PAGE: `${BASE_ADMIN_PATH}/collections/`,

  SECTIONS_PAGE: `${BASE_ADMIN_PATH}/sections/`,
  ROUTES_PAGE: `${BASE_ADMIN_PATH}/routes/`,
  ROUTE_ITEM_PAGE: `${BASE_ADMIN_PATH}/routes/:id/`,
  PAGES_PAGE: `${BASE_ADMIN_PATH}/pages/`,
  PAGE_ITEM_PAGE: `${BASE_ADMIN_PATH}/pages/:id/`,
};

function AdminRoute({ component: Component, showSimpleLayout, ...rest }) {
  const dispatch = useDispatch();
  const { statuses, data } = useSelector((state) => state.admin.user);

  const isAuthorized = statuses.login.success || statuses.session.success;
  const isNotAdmin = data?.role !== STAFF_ROLES.ADMIN;
  const isLoginPage = rest.path === ADMIN_PATHS.LOGIN_PAGE;
  const isStaffsPage = rest.path === ADMIN_PATHS.STAFFS_PAGE;

  useEffect(() => {
    if (statuses.session.initial) {
      dispatch({ type: GET_USER_SESSION });
    }
  }, [dispatch, statuses.session.initial]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (statuses.session.initial || statuses.session.loading) {
          return <Loader />;
        }

        if (isAuthorized && (isLoginPage || (isStaffsPage && isNotAdmin))) {
          return <Redirect to={ADMIN_PATHS.ROUTES_PAGE} />;
        }

        if (!isLoginPage && (!isAuthorized || statuses.session.error)) {
          return <Redirect to={ADMIN_PATHS.LOGIN_PAGE} />;
        }

        return (
          <LazyAdminLayout userName={data?.name} showSimpleLayout={showSimpleLayout}>
            <Component {...props} />
          </LazyAdminLayout>
        );
      }}
    />
  );
}

AdminRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.element, PropTypes.object]).isRequired,
  showSimpleLayout: PropTypes.bool,
};

AdminRoute.defaultProps = {
  showSimpleLayout: false,
};

export default AdminRoute;
