import React, { useCallback } from 'react';
import cls from 'classnames';

import Container from 'components/base/Container';

import { propTypes, defaultProps } from '../propTypes';

import commonStyles from '../Common.module.scss';
import skeletonStyles from './Skeleton.module.scss';

function FooterSkeleton({ leftGroup, rightGroup, showAppLinks, appLinks, text }) {
  const renderContentBlock = useCallback(() => {
    if (showAppLinks) {
      return (
        <div className={cls(commonStyles.col, commonStyles.content, commonStyles.appLinks)}>
          {appLinks.map((appLink) => (
            <div key={appLink.key} className={cls(commonStyles.appLink, skeletonStyles.appLink)} />
          ))}
        </div>
      );
    }

    if (!text) {
      return null;
    }

    return (
      <div className={cls(commonStyles.col, commonStyles.content)}>
        <div className={skeletonStyles.contentSkeleton} />
      </div>
    );
  }, [text, appLinks, showAppLinks]);

  return (
    <footer className={commonStyles.ComponentRoot}>
      <Container>
        <div className={commonStyles.wrapper}>
          <div className={cls(commonStyles.col, commonStyles.group, commonStyles.leftGroup)}>
            {leftGroup.map(({ key }) => (
              <div key={key} className={cls(commonStyles.item, skeletonStyles.linkItem)} />
            ))}
          </div>
          <div className={cls(commonStyles.col, commonStyles.group, commonStyles.rightGroup)}>
            {rightGroup.map(({ key }) => (
              <div key={key} className={cls(commonStyles.item, skeletonStyles.linkItem)} />
            ))}
          </div>
          {renderContentBlock()}
        </div>
      </Container>
    </footer>
  );
}

FooterSkeleton.propTypes = propTypes;
FooterSkeleton.defaultProps = defaultProps;

export default FooterSkeleton;
