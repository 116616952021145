import { takeEvery } from 'redux-saga/effects';

import { LOGOUT_USER, logoutUser } from './actions/logout';
import { LOGIN_USER_BY_MAIL, loginUserByEmail } from './actions/login';
import { GET_USER_SESSION, getUserSession } from './actions/session';

export default function* userSagas() {
  yield takeEvery(GET_USER_SESSION, getUserSession);
  yield takeEvery(LOGIN_USER_BY_MAIL, loginUserByEmail);
  yield takeEvery(LOGOUT_USER, logoutUser);
}
