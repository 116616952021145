import { call, put } from 'redux-saga/effects';

import { landingsAxios } from 'utilities/axios';
import requestWithDelay from 'utilities/requestWithDelay';
import errorParser from 'storage/utilities/errorParser';

import { PAGE_REDUCER_PATH } from 'constants/reducerPaths';

import { INIT_RELOAD as ROUTES_INIT_RELOAD } from '../../routes/actions/initReload';
import { INIT_RELOAD as PAGES_INIT_RELOAD } from '../../pages/full/actions/initReload';
import { itemChangingActions } from '../slice';

export const COPY_ITEM = `${PAGE_REDUCER_PATH}/COPY_ITEM`;

const errorMapper = new Map([
  [
    'Default not found',
    [{ type: 'common', title: 'Ошибка создания копии', message: 'Страница не найдена' }],
  ],
]);

export function* copyItem({ pageId, routeId, meta }) {
  try {
    errorMapper.set(`Page ${pageId} not found`, errorMapper.get('Default not found'));

    const { data } = yield call(
      requestWithDelay,
      landingsAxios({
        url: `/pages/${pageId}/copy/${routeId}/`,
        method: 'POST',
      }),
    );

    yield call(meta.resolve, data.id);
    yield put({ type: ROUTES_INIT_RELOAD });
    yield put({ type: PAGES_INIT_RELOAD });
  } catch (error) {
    yield put(itemChangingActions.error(errorParser({ error, errorMapper })));
    yield call(meta.reject);
  }
}
