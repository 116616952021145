import { createSlice } from '@reduxjs/toolkit';
import * as BASE_LOADABLE from 'storage/utilities/baseLoadable';

const initialState = {
  statuses: BASE_LOADABLE.initial(),
  shouldReload: false,
  data: null,
  children: {},
  total: null,
  error: null,
};

const { reducer, actions } = createSlice({
  name: 'boundaries.full',
  initialState,
  reducers: {
    resetStateToInitial: () => initialState,
    clearErrorStatus: (state) => {
      state.statuses = BASE_LOADABLE.clear();
      state.error = null;
    },
    startListRequest: (state) => {
      state.statuses = BASE_LOADABLE.begin();
      state.data = null;
      state.children = {};
      state.error = null;
      state.shouldReload = false;
    },
    successfulListRequest: (state, action) => {
      state.statuses = BASE_LOADABLE.success();
      state.data = action.payload.data;
      state.total = action.payload.total;
    },
    listRequestWithError: (state, action) => {
      state.statuses = BASE_LOADABLE.error();
      state.error = action.payload;
    },
    startChildrenRequest: (state, action) => {
      state.children[action.payload] = {
        statuses: BASE_LOADABLE.begin(),
        error: null,
        data: null,
      };
    },
    successfulChildrenRequest: (state, action) => {
      state.children[action.payload.id] = {
        ...state.children[action.payload.id],
        statuses: BASE_LOADABLE.success(),
        data: action.payload.data,
      };
    },
    childrenRequestWithError: (state, action) => {
      state.children[action.payload.id] = {
        ...state.children[action.payload.id],
        statuses: BASE_LOADABLE.error(),
        data: action.payload.error,
      };
    },
    startItemRequest: (state) => {
      state.statuses = BASE_LOADABLE.begin();
      state.error = null;
    },
    successfulItemRequest: (state) => {
      state.shouldReload = true;
    },
    itemRequestWithError: (state, action) => {
      state.statuses = BASE_LOADABLE.error();
      state.error = action.payload;
    },
  },
});

export const listActions = {
  start: actions.startListRequest,
  success: actions.successfulListRequest,
  error: actions.listRequestWithError,
};

export const childrenActions = {
  start: actions.startChildrenRequest,
  success: actions.successfulChildrenRequest,
  error: actions.childrenRequestWithError,
};

export const itemChangingActions = {
  start: actions.startItemRequest,
  success: actions.successfulItemRequest,
  error: actions.itemRequestWithError,
};

export const { clearErrorStatus, resetStateToInitial } = actions;

export default reducer;
