import { takeEvery } from 'redux-saga/effects';

import { GET_ONE_ITEM, getItem } from './actions/getItem';
import { CLEAR_ERROR, clearError } from './actions/clearError';
import { RESET_TO_INITIAL, resetToInitial } from './actions/resetToInitial';

export default function* tagsPointsSagas() {
  yield takeEvery(GET_ONE_ITEM, getItem);
  yield takeEvery(CLEAR_ERROR, clearError);
  yield takeEvery(RESET_TO_INITIAL, resetToInitial);
}
