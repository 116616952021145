export const STAFF_ROLES = {
  ADMIN: 'ADMIN',
  MANAGER: 'MANAGER',
  CI: 'CI',
};

export const USER_ROLES = {
  BASE: 'BASE',
  PRO: 'PRO',
  ANONYMOUS: 'ANONYMOUS',
};
