import envConfig, { NODE_ENV_VARIANTS } from 'constants/config';

export default function sendEvent(eventObj = {}) {
  if (envConfig.NODE_ENV !== NODE_ENV_VARIANTS.PRODUCTION) {
    // eslint-disable-next-line no-console
    console.log('data-layer-event--->', eventObj);
    return;
  }

  if (!window.dataLayer) {
    return;
  }

  window.dataLayer.push(eventObj);
}
