import { put } from 'redux-saga/effects';

import { PAGE_REDUCER_PATH } from 'constants/reducerPaths';

import { swapSections } from '../slice';

export const SWAP_SECTIONS = `${PAGE_REDUCER_PATH}/SWAP_SECTIONS`;

export function* initSectionsSwap({ left, right }) {
  yield put(swapSections({ leftId: left, rightId: right }));
}
