import { createSlice } from '@reduxjs/toolkit';
import * as BASE_LOADABLE from 'storage/utilities/baseLoadable';

const initialState = {
  statuses: BASE_LOADABLE.initial(),
  shouldReload: false,
  data: null,
  total: null,
  error: null,
};

const { reducer, actions } = createSlice({
  name: 'boundaries.single.border',
  initialState,
  reducers: {
    resetStateToInitial: () => initialState,
    clearErrorStatus: (state) => {
      state.statuses = BASE_LOADABLE.clear();
      state.error = null;
    },
    startItemRequest: (state) => {
      state.statuses = BASE_LOADABLE.begin();
      state.error = null;
    },
    successfulItemRequest: (state, action) => {
      state.statuses = BASE_LOADABLE.success();
      state.data = action.payload;
    },
    itemRequestWithError: (state, action) => {
      state.statuses = BASE_LOADABLE.error();
      state.error = action.payload;
    },
  },
});

export const singleItemActions = {
  start: actions.startItemRequest,
  success: actions.successfulItemRequest,
  error: actions.itemRequestWithError,
};

export const { clearErrorStatus, resetStateToInitial } = actions;

export default reducer;
