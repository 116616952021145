import React from 'react';
import cls from 'classnames';

import ButtonSkeleton, { COLOR_VARIANTS as BUTTON_COLORS } from 'components/atoms/Button/Skeleton';

import { skeletonPropTypes, defaultProps } from '../propTypes';

import commonStyles from '../Common.module.scss';
import skeletonStyles from './Skeleton.module.scss';

function SubscribeFormSkeleton({ className, actionSize, fields }) {
  if (!fields.length) {
    return null;
  }

  return (
    <div className={cls(commonStyles.SubscribeForm, className)}>
      <div className={commonStyles.fields}>
        {fields.map((field) => (
          <div key={field.name} className={cls(commonStyles.field, skeletonStyles.field)} />
        ))}
      </div>
      <div className={commonStyles.actionWrapper}>
        <ButtonSkeleton
          className={skeletonStyles.action}
          color={BUTTON_COLORS.BASE_DARK}
          size={actionSize}
        />
      </div>
    </div>
  );
}

SubscribeFormSkeleton.propTypes = skeletonPropTypes;
SubscribeFormSkeleton.defaultProps = defaultProps;

export default SubscribeFormSkeleton;
