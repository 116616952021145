import { takeEvery, put } from 'redux-saga/effects';

import { CREATE_ITEM as BASE } from 'constants/adminActions';

import { CREATE_ITEM_WITH_NEW_PAGE, createItemWithNewPage } from './createItemWithNewPage';
import { CREATE_ITEM_WITH_EXISTING_PAGE, createWithExistingPage } from './createWithExistingPage';

const CREATE_ITEM = `admin.routes/${BASE}`;

function* createItem({ formData }) {
  if (formData.page === 'New-Page') {
    yield put({ type: CREATE_ITEM_WITH_NEW_PAGE, formData });
    return;
  }

  yield put({ type: CREATE_ITEM_WITH_EXISTING_PAGE, formData });
}

export function* createItemSagas() {
  yield takeEvery(CREATE_ITEM, createItem);
  yield takeEvery(CREATE_ITEM_WITH_NEW_PAGE, createItemWithNewPage);
  yield takeEvery(CREATE_ITEM_WITH_EXISTING_PAGE, createWithExistingPage);
}
