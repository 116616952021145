import { call, put } from 'redux-saga/effects';

import { landingsAxios } from 'utilities/axios';
import errorParser from 'storage/utilities/errorParser';

import { SWAP_ITEMS as BASE } from 'constants/adminActions';
import { SECTION_REDUCER_PATH } from 'constants/reducerPaths';

import { SWAP_SECTIONS as PAGE_SWAP_SECTIONS } from '../../page/actions/swapSections';
import { itemChangingActions } from '../slice';

export const SWAP_ITEMS = `${SECTION_REDUCER_PATH}/${BASE}`;

const errorMapper = new Map([
  [
    'left must be an UUID',
    {
      type: 'common',
      title: 'Ошибка перемещения',
      message: 'Передано неправильное ID верхнего блока!',
    },
  ],
  [
    'left should not be empty',
    {
      type: 'common',
      title: 'Ошибка перемещения',
      message: 'Передано неправильное ID верхнего блока!',
    },
  ],
  [
    'right must be an UUID',
    {
      type: 'common',
      title: 'Ошибка перемещения',
      message: 'Передано неправильное ID нижнего блока!',
    },
  ],
  [
    'right should not be empty',
    {
      type: 'common',
      title: 'Ошибка перемещения',
      message: 'Передано неправильное ID нижнего блока!',
    },
  ],
  [
    'Default not found',
    [
      {
        type: 'common',
        title: 'Ошибка перемещения',
        message: 'Передан неверный ID одного из блоков',
      },
    ],
  ],
]);

export function* swapItems({ left, right, meta }) {
  try {
    errorMapper.set(`SectionItem ${left} not found`, errorMapper.get('Default not found'));
    errorMapper.set(`SectionItem ${right} not found`, errorMapper.get('Default not found'));

    yield put({ type: PAGE_SWAP_SECTIONS, left, right });

    yield call(landingsAxios, {
      url: '/sections/items/swap/',
      method: 'PATCH',
      data: { left, right },
    });

    yield call(meta.resolve);
  } catch (error) {
    yield put(itemChangingActions.error(errorParser({ error, errorMapper })));
    yield put({ type: PAGE_SWAP_SECTIONS, left, right });
    yield call(meta.reject);
  }
}
