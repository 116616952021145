import { takeEvery } from 'redux-saga/effects';

import {
  FETCH_MARKUP_WITHOUT_CACHE,
  fetchMarkupWithoutCache,
} from './actions/fetchMarkupWithoutCache';
import { FETCH_MARKUP_WITH_CACHE, fetchMarkupWithCache } from './actions/fetchMarkupWithCache';

export default function* markupsSagas() {
  yield takeEvery(FETCH_MARKUP_WITHOUT_CACHE, fetchMarkupWithoutCache);
  yield takeEvery(FETCH_MARKUP_WITH_CACHE, fetchMarkupWithCache);
}
