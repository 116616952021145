import { call, put } from 'redux-saga/effects';

import { coreAxios } from 'utilities/axios';
import requestWithDelay from 'utilities/requestWithDelay';
import errorParser from 'storage/utilities/errorParser';

import { GET_ONE_ITEM as BASE } from 'constants/adminActions';
import { BOUNDARIES_SINGLE_BORDER_REDUCER_PATH } from 'constants/reducerPaths';

import { singleItemActions } from '../slice';

export const GET_ONE_ITEM = `${BOUNDARIES_SINGLE_BORDER_REDUCER_PATH}/${BASE}`;

const errorMapper = new Map([
  ['Default not found', [{ type: 'common', title: 'Граница не найдена' }]],
]);

export function* getItem({ id }) {
  try {
    errorMapper.set(`Boundary ${id} not found`, errorMapper.get('Default not found'));

    yield put(singleItemActions.start());
    const { data } = yield call(
      requestWithDelay,
      coreAxios({
        url: `/boundaries/${id}/border/`,
        method: 'GET',
      }),
    );

    yield put(singleItemActions.success(data));
  } catch (error) {
    yield put(singleItemActions.error(errorParser({ error, errorMapper })));
  }
}
