import PropTypes from 'prop-types';
import { ALIGN_VARIANTS, COLOR_VARIANTS, TAG_VARIANTS } from './constants';

export const propTypes = {
  className: PropTypes.string,
  tag: PropTypes.oneOf(Object.values(TAG_VARIANTS)).isRequired,
  size: PropTypes.oneOf(Object.values(TAG_VARIANTS)),
  align: PropTypes.oneOf(Object.values(ALIGN_VARIANTS)),
  color: PropTypes.oneOf(Object.values(COLOR_VARIANTS)),
};

export const defaultProps = {
  className: undefined,
  size: undefined,
  align: ALIGN_VARIANTS.LEFT,
  color: COLOR_VARIANTS.BASE_DARK,
};
