import { takeEvery, put } from 'redux-saga/effects';

import { UPDATE_ITEM as BASE } from 'constants/adminActions';

import { UPDATE_ITEM_WITH_NEW_PAGE, updateItemWithNewPage } from './updateItemWithNewPage';
import {
  UPDATE_ITEM_WITH_EXISTING_PAGE,
  updateItemWithExistingPage,
} from './updateItemWithExistingPage';

const UPDATE_ITEM = `admin.routes/${BASE}`;

function* updateItem({ type, ...restData }) {
  if (restData.formData.page === 'New-Page') {
    yield put({ type: UPDATE_ITEM_WITH_NEW_PAGE, ...restData });
    return;
  }

  yield put({ type: UPDATE_ITEM_WITH_EXISTING_PAGE, ...restData });
}

export function* updateItemSagas() {
  yield takeEvery(UPDATE_ITEM, updateItem);
  yield takeEvery(UPDATE_ITEM_WITH_NEW_PAGE, updateItemWithNewPage);
  yield takeEvery(UPDATE_ITEM_WITH_EXISTING_PAGE, updateItemWithExistingPage);
}
