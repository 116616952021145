import React, { useCallback } from 'react';
import cls from 'classnames';

import useIsMounted from 'hooks/useIsMounted';

import Container from 'components/base/Container';

import TitleSkeleton, { TAG_VARIANTS as TITLE_TAGS } from 'components/atoms/Title/Skeleton';
import ParagraphsSkeleton from 'components/atoms/Paragraphs/Skeleton';

import useTitleAlign from '../hooks/useTitleAlign';
import useParagraphsAlign from '../../hooks/useParagraphsAlign';

import { propTypes, defaultProps } from '../propTypes';

import commonStyles from '../Common.module.scss';
import skeletonStyles from './Skeleton.module.scss';

function FeatureDescriptionSkeleton({ direction, paragraphs, media }) {
  const isMounted = useIsMounted();

  const titleAlign = useTitleAlign(isMounted);
  const paragraphsAlign = useParagraphsAlign({
    isMounted,
    skipTablet: true,
  });

  const renderMediaBlock = useCallback(() => {
    if (!media) {
      return null;
    }

    return (
      <div className={commonStyles.preview}>
        <div
          className={commonStyles.mediaWrapper}
          style={media.rotate ? { transform: `rotate(${media.rotate})` } : undefined}
        >
          <div
            className={cls(
              commonStyles.media,
              commonStyles[`${media.shadow}Shadow`],
              skeletonStyles.media,
            )}
          />
        </div>
      </div>
    );
  }, [media]);

  return (
    <div className={commonStyles.ComponentRoot}>
      <Container>
        <div className={cls(commonStyles.wrapper, commonStyles[`${direction}Direction`])}>
          <div className={cls(commonStyles.block, commonStyles.previewWrapper)}>
            {renderMediaBlock()}
          </div>
          <div className={cls(commonStyles.block, commonStyles.contentWrapper)}>
            <div className={skeletonStyles.content}>
              <TitleSkeleton
                tag={TITLE_TAGS.h2}
                align={titleAlign}
                contentClassName={skeletonStyles.titleContent}
              />
              {Boolean(paragraphs) && (
                <ParagraphsSkeleton className={commonStyles.description} align={paragraphsAlign}>
                  {paragraphs}
                </ParagraphsSkeleton>
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

FeatureDescriptionSkeleton.propTypes = propTypes;
FeatureDescriptionSkeleton.defaultProps = defaultProps;

export default FeatureDescriptionSkeleton;
