import { createSlice } from '@reduxjs/toolkit';
import * as BASE_LOADABLE from 'storage/utilities/baseLoadable';

const initialState = {
  statuses: BASE_LOADABLE.initial(),
  shouldReload: false,
  data: null,
  error: null,
};

const { reducer, actions } = createSlice({
  name: 'page',
  initialState,
  reducers: {
    resetStateToInitial: () => initialState,
    clearErrorStatus: (state) => {
      state.statuses = BASE_LOADABLE.clear();
      state.error = null;
    },
    initReload: (state) => {
      if (!state.data) {
        return;
      }

      state.shouldReload = true;
    },
    startAllRequest: (state) => {
      state.statuses = BASE_LOADABLE.begin();
      state.data = null;
      state.error = null;
      state.shouldReload = false;
    },
    successfulAllRequest: (state, action) => {
      state.statuses = BASE_LOADABLE.success();
      state.data = action.payload;
    },
    allRequestWithError: (state, action) => {
      state.statuses = BASE_LOADABLE.error();
      state.error = action.payload;
    },
    startItemRequest: (state) => {
      state.statuses = BASE_LOADABLE.begin();
      state.error = null;
    },
    successfulItemRequest: (state) => {
      state.shouldReload = true;
    },
    itemRequestWithError: (state, action) => {
      state.statuses = BASE_LOADABLE.error();
      state.error = action.payload;
    },
    swapSections: (state, action) => {
      const { leftId, rightId } = action.payload;

      const sections = state.data?.sections ?? [];

      if (!sections.length) {
        return;
      }

      const leftSection = sections.find((item) => item.id === leftId);
      const rightSection = sections.find((item) => item.id === rightId);

      if (!leftSection || !rightSection) {
        return;
      }

      [leftSection.position, rightSection.position] = [rightSection.position, leftSection.position];
      sections.sort((sectionA, sectionB) => sectionA.position - sectionB.position);
    },
  },
});

export const oneItemActions = {
  start: actions.startAllRequest,
  success: actions.successfulAllRequest,
  error: actions.allRequestWithError,
};

export const itemChangingActions = {
  start: actions.startItemRequest,
  success: actions.successfulItemRequest,
  error: actions.itemRequestWithError,
};

export const { resetStateToInitial, clearErrorStatus, initReload, swapSections } = actions;

export default reducer;
