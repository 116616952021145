import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import LazyAppLayout from 'layout/App/lazy';

function AppRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <LazyAppLayout>
          <Component {...props} />
        </LazyAppLayout>
      )}
    />
  );
}

AppRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.element, PropTypes.object]).isRequired,
};

export default AppRoute;
