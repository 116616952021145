import axiosCore from 'axios';

import envConfig from 'constants/config';
import { API_PREFIXES } from 'constants/apiPrefixes';

import { BASE_ADMIN_PATH, ADMIN_PATHS } from 'routes/withLayouts/AdminRoute';

function rejectError(error) {
  return new Promise((_, reject) => reject(error));
}

export function createAxios(apiPrefix) {
  const axios = axiosCore.create({
    baseURL: envConfig[`REACT_APP_${apiPrefix}`],
    headers: {
      'Content-Type': 'application/json',
    },
  });

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.config.url === '/auth/refresh/') {
        return rejectError(error);
      }

      if (error.response.status !== 401) {
        return rejectError(error);
      }

      try {
        await axios({
          baseURL: envConfig[`REACT_APP_${API_PREFIXES.CORE_API}`],
          url: '/auth/refresh/',
          method: 'POST',
        });

        return axios(error.config);
      } catch (err) {
        if (typeof window !== 'undefined') {
          const { href, pathname } = window.location;
          if (pathname !== ADMIN_PATHS.LOGIN_PAGE && href.includes(BASE_ADMIN_PATH)) {
            window.location.href = ADMIN_PATHS.LOGIN_PAGE;
          }
        }

        return rejectError(err);
      }
    },
  );

  return axios;
}

export const landingsAxios = createAxios(API_PREFIXES.LANDINGS_API);

export const coreAxios = createAxios(API_PREFIXES.CORE_API);
