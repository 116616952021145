export default function plainObjectToFormData(values) {
  return Object.entries(values).reduce((acc, [key, value]) => {
    if (typeof value !== 'object') {
      acc.append(key, value);
      return acc;
    }

    if (value == null) {
      acc.append(key, '');
      return acc;
    }

    if ('originFileObj' in value) {
      acc.append('image', value.originFileObj);
      return acc;
    }

    if (Array.isArray(value) && value.length === 0) {
      acc.append(key, '[]');
      return acc;
    }

    Object.entries(value).forEach(([subKey, subValue]) => {
      acc.append(`${key}[${subKey}]`, subValue);
    });
    return acc;
  }, new FormData());
}
