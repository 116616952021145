import { createBrowserHistory } from 'history';
import qhistory from 'qhistory';
import { stringify, parse } from 'qs';

import createHashLinkScroll from 'utilities/createHashLinkScroll';

const historyParams = {
  basename: '',
};

function createHistory(params) {
  const scrollTo = ({ left, top, behavior = 'auto' }) => {
    setTimeout(window.scrollTo({ top, left, behavior }), 0);
  };
  const history = createBrowserHistory(params);

  if (history.location.hash) {
    createHashLinkScroll({ anchor: history.location.hash, smooth: true })();
    history.replace(`${history.location.pathname}?${history.location.search}`);
  }

  history.listen((location) => {
    if (location.action === 'POP') {
      return;
    }

    if (location.state && location.state.ignoreScroll) {
      return;
    }

    scrollTo({ left: 0, top: 0 });
  });

  return history;
}

export default qhistory(createHistory(historyParams), stringify, parse);
