import PropTypes from 'prop-types';

export const propTypes = {
  title: PropTypes.node.isRequired,
  paragraphs: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  action: PropTypes.oneOfType([
    () => null,
    PropTypes.shape({
      href: PropTypes.string,
      onClick: PropTypes.func,
      text: PropTypes.node.isRequired,
      eventData: PropTypes.object,
    }),
  ]),
  illustration: PropTypes.oneOfType([
    () => null,
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
      presentation: PropTypes.bool,
    }),
  ]),
  backgroundImage: PropTypes.oneOfType([
    () => null,
    PropTypes.shape({
      desktop: PropTypes.string,
      mobile: PropTypes.string,
    }),
  ]),
};

export const defaultProps = {
  paragraphs: '',
  action: null,
  illustration: null,
  backgroundImage: null,
};
