import { call, put } from 'redux-saga/effects';

import { landingsAxios } from 'utilities/axios';
import requestWithDelay from 'utilities/requestWithDelay';
import errorParser from 'storage/utilities/errorParser';

import { GET_ONE_ITEM as BASE } from 'constants/adminActions';
import { PAGE_REDUCER_PATH } from 'constants/reducerPaths';

import { oneItemActions } from '../slice';

export const GET_ONE_ITEM = `${PAGE_REDUCER_PATH}/${BASE}`;

const errorMapper = new Map([
  ['Default not found', [{ type: 'common', title: 'Страница не найдена' }]],
]);

export function* getOneItem({ id }) {
  try {
    errorMapper.set(`Page ${id} not found`, errorMapper.get('Default not found'));

    yield put(oneItemActions.start());
    const { data } = yield call(
      requestWithDelay,
      landingsAxios({
        url: `/pages/${id}/`,
        method: 'GET',
      }),
    );

    yield put(oneItemActions.success(data));
  } catch (error) {
    yield put(oneItemActions.error(errorParser({ error, errorMapper })));
  }
}
