import React from 'react';
import cls from 'classnames';

import Container from 'components/base/Container';

import TitleSkeleton, {
  TAG_VARIANTS as TITLE_TAGS,
  SIZE_VARIANTS as TITLE_SIZES,
  ALIGN_VARIANTS as TITLE_ALIGNS,
} from 'components/atoms/Title/Skeleton';
import ParagraphsSkeleton, {
  ALIGN_VARIANTS as PARAGRAPHS_ALIGNS,
  COLOR_VARIANTS as PARAGRAPHS_COLORS,
} from 'components/atoms/Paragraphs/Skeleton';
import ButtonSkeleton, {
  SIZES_VARIANTS as BUTTON_SIZES,
  COLOR_VARIANTS as BUTTON_COLORS,
} from 'components/atoms/Button/Skeleton';

import { propTypes, defaultProps } from '../propTypes';

import commonStyles from '../Common.module.scss';
import skeletonStyles from './Skeleton.module.scss';

function BaseScreenSkeleton({ paragraphs, illustration, action }) {
  return (
    <div className={commonStyles.ComponentRoot}>
      <Container>
        <div className={commonStyles.wrapper}>
          <div className={commonStyles.content}>
            {illustration && (
              <div className={cls(commonStyles.illustration, skeletonStyles.illustration)} />
            )}
            <TitleSkeleton tag={TITLE_TAGS.h1} size={TITLE_SIZES.div} align={TITLE_ALIGNS.CENTER} />
            {paragraphs && (
              <ParagraphsSkeleton
                className={commonStyles.description}
                align={PARAGRAPHS_ALIGNS.CENTER}
                color={PARAGRAPHS_COLORS.DARK_200}
              >
                {paragraphs}
              </ParagraphsSkeleton>
            )}
            {action && (
              <div className={commonStyles.actionWrapper}>
                <ButtonSkeleton size={BUTTON_SIZES.SMALL} color={BUTTON_COLORS.BASE_DARK} />
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}

BaseScreenSkeleton.propTypes = propTypes;
BaseScreenSkeleton.defaultProps = defaultProps;

export default BaseScreenSkeleton;
