import { call, put } from 'redux-saga/effects';

import { landingsAxios } from 'utilities/axios';
import requestWithDelay from 'utilities/requestWithDelay';
import errorParser from 'storage/utilities/errorParser';

import { DELETE_ITEM as BASE } from 'constants/adminActions';
import { SECTION_TYPES_REDUCER_PATH } from 'constants/reducerPaths';

import { itemChangingActions } from '../slice';

export const DELETE_ITEM = `${SECTION_TYPES_REDUCER_PATH}/${BASE}`;

const errorMapper = new Map([
  ['Default not found', [{ type: 'common', title: 'Ошибка удаления', message: 'Блок не найден' }]],
]);

export function* deleteItem({ id, meta }) {
  try {
    errorMapper.set(`SectionType ${id} not found`, errorMapper.get('Default not found'));

    yield call(
      requestWithDelay,
      landingsAxios({
        url: `/sections/types/${id}/`,
        method: 'DELETE',
      }),
    );

    yield put(itemChangingActions.success());
    yield call(meta.resolve);
  } catch (error) {
    yield put(itemChangingActions.error(errorParser({ error, errorMapper })));
    yield call(meta.reject);
  }
}
