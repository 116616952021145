import { useMemo } from 'react';

import { ALIGN_VARIANTS } from 'components/atoms/Paragraphs/constants';

import { useLayoutContext } from 'utilities/layoutContext';

export default function useParagraphsAlign({ isMounted, skipTablet, skipWideMobile }) {
  const { isMobileLayout, isMobileWideLayout, isTabletLayout } = useLayoutContext();

  return useMemo(() => {
    if (!isMounted) {
      return ALIGN_VARIANTS.LEFT;
    }

    switch (true) {
      case isMobileLayout:
      case !skipWideMobile && isMobileWideLayout: {
        return ALIGN_VARIANTS.CENTER;
      }
      case !skipTablet && isTabletLayout: {
        return ALIGN_VARIANTS.CENTER;
      }
      default: {
        return ALIGN_VARIANTS.LEFT;
      }
    }
  }, [isMounted, skipTablet, skipWideMobile, isMobileLayout, isMobileWideLayout, isTabletLayout]);
}
