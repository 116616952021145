import PropTypes from 'prop-types';

import _omit from 'lodash/omit';

import { sizeProp } from 'components/atoms/Button/propTypes';
import { SIZES_VARIANTS } from 'components/atoms/Button/constants';

import { TYPE_VARIANTS } from './constants';

export const fieldPropTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(TYPE_VARIANTS)),
  validation: PropTypes.shape({
    required: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.exact({ value: PropTypes.bool, message: PropTypes.string }),
    ]),
    pattern: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.exact({ value: PropTypes.string, message: PropTypes.string }),
    ]),
  }),
  mask: PropTypes.oneOfType([() => null, PropTypes.string]),
};

export const fieldDefaultProps = {
  type: TYPE_VARIANTS.TEXT,
  validation: {},
  mask: null,
};

export const modalContentProps = {
  illustration: PropTypes.string,
  title: PropTypes.node.isRequired,
  paragraphs: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  action: PropTypes.node,
};

export const propTypes = {
  className: PropTypes.string,
  apiPrefix: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape(fieldPropTypes)),
  actionSize: sizeProp,
  actionText: PropTypes.node.isRequired,
  agreementText: PropTypes.node.isRequired,
  skipFieldEvents: PropTypes.bool,
  eventData: PropTypes.shape({}),
  onSuccessSubmit: PropTypes.func.isRequired,
  onErrorSubmit: PropTypes.func.isRequired,
};

export const skeletonPropTypes = _omit(propTypes, ['onSuccessSubmit', 'onErrorSubmit']);

export const defaultProps = {
  className: undefined,
  actionSize: SIZES_VARIANTS.LARGE,
  fields: [],
  skipFieldEvents: false,
  eventData: {},
};

export const modalPropTypes = {
  form: PropTypes.shape(
    _omit(propTypes, ['isMounted', 'className', 'onSuccessSubmit', 'onErrorSubmit', 'actionSize']),
  ).isRequired,
  modals: PropTypes.exact({
    success: PropTypes.shape(modalContentProps),
    duplicate: PropTypes.shape(modalContentProps),
    error: PropTypes.shape(modalContentProps),
  }).isRequired,
};
