export const TAG_VARIANTS = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  div: 'div',
  // h5: 'h5',
  // h6: 'h6',
};

export const SIZE_VARIANTS = TAG_VARIANTS;

export const ALIGN_VARIANTS = {
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center',
  JUSTIFY: 'justify',
};

export const COLOR_VARIANTS = {
  BASE_DARK: 'baseDark',
  ACCENT: 'accent',
};
