import PropTypes from 'prop-types';

export const propTypes = {
  title: PropTypes.node.isRequired,
  description: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  rules: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      text: PropTypes.node.isRequired,
      subText: PropTypes.string,
      subList: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          text: PropTypes.node.isRequired,
        }),
      ),
    }),
  ),
};

export const defaultProps = {
  description: '',
  rules: [],
};
