import React from 'react';
import cls from 'classnames';

import { COLOR_VARIANTS, SIZES_VARIANTS } from '../constants';
import { propTypes, defaultProps } from '../propTypes';

import styles from './Skeleton.module.scss';

function ButtonSkeleton({ className, color, size, disabled }) {
  return (
    <div
      className={cls(
        styles.Button,
        styles[`${color}Button`],
        styles[`${size}Size`],
        disabled && styles.disabled,
        className,
      )}
    />
  );
}

ButtonSkeleton.propTypes = propTypes;
ButtonSkeleton.defaultProps = defaultProps;

export { COLOR_VARIANTS, SIZES_VARIANTS };
export default ButtonSkeleton;
