const REQUEST_LOADING_MIN_TIME = 400;

export default async function requestWithDelay(request, delay = REQUEST_LOADING_MIN_TIME) {
  const [requestPromise] = await Promise.all([
    request,
    new Promise((resolve) => setTimeout(resolve, delay, 'Delay out')),
  ]);

  return requestPromise;
}
