import routes from './routes';
import markups from './markups';
import admin from './admin';
import common from './common';

export default {
  routes,
  markups,
  admin,
  common,
};
