import { put } from 'redux-saga/effects';

import { fetchMarkupWithCacheActions } from '../slice';
import getMarkup from '../helpers/getMarkup';

export const FETCH_MARKUP_WITH_CACHE = 'markups/FETCH_WITH_CACHE';

export function* fetchMarkupWithCache({ id, path, slug }) {
  try {
    const { data } = yield getMarkup({ path, slug });
    yield put(fetchMarkupWithCacheActions.successLoaded({ id, data }));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}
