import React from 'react';
import PropTypes from 'prop-types';

import loadable from '@loadable/component';
import uxWaitRequest from 'utilities/uxWaitRequest';

import DynamicHelmet from 'components/organisms/DynamicHelmet';

import SkeletonsMap from './SkeletonsMap';

function BlocksRenderer({ config }) {
  return config.map(({ key, name, props }) => {
    if (name === 'DynamicHelmet') {
      return <DynamicHelmet key={key} {...props} />;
    }

    const Skeleton = SkeletonsMap.get(name);
    const Loadable = loadable(
      () =>
        uxWaitRequest(import(/* webpackChunkName: `block-[request]` */ `./view/${name}/Dynamic`)),
      {
        resolveComponent: (modules) => modules.Component,
        fallback: <Skeleton {...props} />,
      },
    );
    return <Loadable key={key} {...props} />;
  });
}

BlocksRenderer.propTypes = {
  config: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
      props: PropTypes.object,
    }),
  ).isRequired,
};

export default BlocksRenderer;
