import React from 'react';
import PropTypes from 'prop-types';

import Loader from 'components/organisms/Loader';

import { DEFAULT_NOT_FOUND_SLUG } from 'constants/common';

import useLoadPageMarkup from 'hooks/useLoadPageMarkup';

import ServerErrorRenderer from './ServerErrorRenderer';
import BlocksRenderer from '../BlocksRenderer';

function NotFoundRenderer({ lang }) {
  const notFoundMarkup = useLoadPageMarkup({ slug: `${lang}-${DEFAULT_NOT_FOUND_SLUG}` });

  if (!notFoundMarkup || notFoundMarkup.statuses.loading) {
    return <Loader />;
  }

  if (!notFoundMarkup?.data) {
    return <ServerErrorRenderer lang={lang} />;
  }

  return <BlocksRenderer config={notFoundMarkup.data ?? []} />;
}

NotFoundRenderer.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default NotFoundRenderer;
