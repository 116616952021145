import { call, put } from 'redux-saga/effects';

import { landingsAxios } from 'utilities/axios';
import requestWithDelay from 'utilities/requestWithDelay';
import errorParser from 'storage/utilities/errorParser';

import { UPDATE_ITEM as BASE } from 'constants/adminActions';
import { PAGE_REDUCER_PATH } from 'constants/reducerPaths';

import { itemChangingActions } from '../slice';

export const UPDATE_ITEM = `${PAGE_REDUCER_PATH}/${BASE}`;

const errorMapper = new Map([
  [
    'Bad Request',
    {
      type: 'common',
      title: 'Ошибка редактирования страницы',
      message: 'Проверьте правильность заполнения полей',
    },
  ],
  [
    'Empty body. Nothing to update',
    [
      {
        type: 'common',
        title: 'Ошибка редактирования страницы',
        message: 'Не передано данных для обновления',
      },
    ],
  ],
  [
    'Default not found',
    [{ type: 'common', title: 'Ошибка редактирования', message: 'Страница не найдена' }],
  ],
]);

export function* updateItem({ id, formData, meta }) {
  try {
    errorMapper.set(`Page ${id} not found`, errorMapper.get('Default not found'));

    yield call(
      requestWithDelay,
      landingsAxios({
        url: `/pages/${id}/`,
        method: 'PATCH',
        data: formData,
      }),
    );

    yield call(meta.resolve);
    yield put(itemChangingActions.success());
  } catch (error) {
    yield put(itemChangingActions.error(errorParser({ error, errorMapper })));
    yield call(meta.reject);
  }
}
