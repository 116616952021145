import { useMemo } from 'react';

import { ALIGN_VARIANTS } from 'components/atoms/Title/constants';

import { useLayoutContext } from 'utilities/layoutContext';

export default function useTitleAlign(isMounted) {
  const { isMobileLayout, isMobileWideLayout } = useLayoutContext();

  return useMemo(() => {
    if (!isMounted) {
      return ALIGN_VARIANTS.LEFT;
    }

    switch (true) {
      case isMobileLayout:
      case isMobileWideLayout: {
        return ALIGN_VARIANTS.CENTER;
      }
      default: {
        return ALIGN_VARIANTS.LEFT;
      }
    }
  }, [isMounted, isMobileLayout, isMobileWideLayout]);
}
